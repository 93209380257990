import { useEffect, useState } from 'react'
import { TruckIcon } from '@heroicons/react/24/outline'

import { usePriceFormatter } from 'src/sdk/product/useFormattedPrice'
import Price from 'src/components/ui/Price'

import styles from './regua.module.scss'
import type { FreteGratisBarProps } from './ReguaDeBeneficios'

export const FreteGratisBar = ({ infopromos, delay }: FreteGratisBarProps) => {
  const [toogle, setToogle] = useState(false)
  const formatter = usePriceFormatter({ decimals: true })

  useEffect(() => {
    setTimeout(() => setToogle(!toogle), delay * 1000)
  }, [toogle, delay])

  return (
    <section
      data-testid="reguaFreteGratis"
      className={`${styles.fsProgressBar} w-full text-center relative bg-gray-light-500 h-[25px] `}
    >
      <div data-fs-toggle={toogle}>
        {infopromos?.map((freteGratisItem, key) => (
          <div
            data-fs-promo-tipo={freteGratisItem.tipo}
            className="absolute transition-all duration-300 w-full h-full left-0 top-0 flex justify-center items-center"
            key={key}
          >
            <TruckIcon className="w-[24px] h-[24px] mr-[3px]" />
            <div data-fs-promo-description />
            <div>
              <strong>Frete grátis</strong>{' '}
              {freteGratisItem.tipo === 'frete-app' && <span>no APP </span>} a
              partir de{' '}
              <span className={styles.promoContentValue}>
                <Price
                  formatter={formatter}
                  value={freteGratisItem.totalValueFloor}
                  SRText="price"
                />
              </span>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
