import { Bars3Icon } from '@heroicons/react/24/outline'
import CartToggle from 'src/components/cart/CartToggle'
import Button from 'src/components/ui/Button'
import Link from 'next/link'
import Logo from 'src/components/ui/Logo'
import { mark } from 'src/sdk/tests/mark'
import { useUI } from 'src/sdk/ui/Provider'
import useScrollDirection from 'src/sdk/ui/useScrollDirection'
import { ReguaDeBeneficios } from 'src/components/common/ReguaDeBeneficios'

import styles from './navbar.module.scss'
import NavbarSlider from './NavbarSlider'
import { SearchInputRich } from '../SearchInput/SearchInputRich'
import { Megamenu } from '../Megamenu'
import { AccountMenu } from '../AccountMenu'
import { HeartButtonHeader } from '../HeartButtonHeader'
import SearchInput from 'src/components/search/SearchInput'
import Icon from 'src/components/ui/Icon'
import { useState } from 'react'
import { vtexSearch } from 'store.config'
import { AcessibilityButtonHeader } from 'src/components/ui/Button/AcessibilityButtonHeader'

function Navbar() {
  const scrollDirection = useScrollDirection()
  const { openNavbar, navbar: displayNavbar } = useUI()

  const [searchInputOpened, setSearchInputOpened] = useState(false)

  const onSearchClick = () => {
    setSearchInputOpened(!searchInputOpened)
  }

  return (
    <header
      data-fs-navbar
      data-fs-navbar-scroll={scrollDirection}
      className={`${styles.fsNavbar} layout__content-full top-0 drop-shadow`}
    >
      <ReguaDeBeneficios />
      <section data-fs-navbar-header data-search-input-open={searchInputOpened}>
        <div className="header_top container flex flex-wrap justify-between items-center bg-white flex-row px-2 pb-2 pt-1">
          <div className="flex">
            <Button
              variant="tertiary"
              className="xl:hidden"
              data-fs-button-icon
              data-fs-navbar-button-menu
              aria-label="Open Menu"
              icon={<Bars3Icon name="list" className="w-[30px]" />}
              onClick={openNavbar}
            />
            <Link
              href="/"
              aria-label="Ir para a página inicial"
              title="Ir para a página inicial"
              data-fs-navbar-logo
            >
              <a>
                <Logo />
              </a>
            </Link>
          </div>

          {vtexSearch ? (
            <div data-content-search>
              <SearchInput />

              <Button
                variant="tertiary"
                data-fs-button-icon
                data-fs-navbar-button-menu
                data-buton-control-search-mobile
                aria-label="Open Search"
                icon={
                  <Icon
                    data-icon-search-mobile
                    name={searchInputOpened ? 'X' : 'MagnifyingGlass'}
                  />
                }
                onClick={onSearchClick}
                data-testid="btn-open-search-mobile"
              />
            </div>
          ) : (
            <SearchInputRich />
          )}

          <div data-fs-navbar-buttons>
            <AccountMenu />
            <AcessibilityButtonHeader />
            <HeartButtonHeader
              showTooltip
              tooltipContent="Favoritos"
              className="xs:w-[32px] xs:h-[32px] w-[28px] h-[28px] text-pink"
              tabIndex={0}
              href="/_secure/favoritos"
            />
            <CartToggle />
          </div>
        </div>
        <Megamenu />
      </section>

      {displayNavbar && <NavbarSlider />}
    </header>
  )
}

Navbar.displayName = 'Navbar'

export default mark(Navbar)
