import { useState } from 'react'

import { ChevronDownIcon, MinIcon } from 'src/components/ui/ImageGallery/Icons'

import type { FooterType } from './Footer'

interface FooterColumnsProps {
  dadosCMS: FooterType
}

export const FooterColumns = (props: FooterColumnsProps) => {
  const { dadosCMS } = props
  const [selected, setSelected] = useState<number>(-1)
  const handleClick = (i: number) => {
    if (selected === i) {
      return setSelected(-1)
    }

    return setSelected(i)
  }

  return (
    <>
      {dadosCMS?.allItems?.map((coluna, index) => {
        return (
          <div
            className="lg:pt-[20px] xl:pr-[60px] mb-[5px] pt-[8px] pr-[15px] pb-[8px] pl-[15px] lg:mr-[10px] xl:mr-0"
            key={index}
          >
            <div
              className="lg:font-bold lg:text-[16px] flex justify-between align-middle cursor-pointer lg:mb-0 mb-1"
              onClick={() => handleClick(index)}
              aria-hidden="true"
              data-testid="menuColumnTitle"
            >
              <span className="lg:pb-1 mb-1 mt-1 whitespace-nowrap">
                {coluna.title}
              </span>
              <span className="lg:hidden text-sm mt-[10px]" data-testid="setas">
                {selected === index ? (
                  <MinIcon color="black" />
                ) : (
                  <ChevronDownIcon color="white" />
                )}
              </span>
            </div>
            {coluna.allItems.map((subtitulo, key) => {
              return (
                <div
                  className={
                    selected === index ? 'conteudo visivel' : 'conteudo'
                  }
                  key={key}
                  data-testid="menuSubcolumn"
                >
                  <a href={subtitulo.link ?? '#'} key={key}>
                    <span className="lg:text-[14px] lg:leading-[26px] text-sm whitespace-nowrap">
                      {subtitulo.texto}
                    </span>
                  </a>
                </div>
              )
            })}
          </div>
        )
      })}
    </>
  )
}
