import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

import { versaoPrivacidade } from '../../../../store.config'

export const PoliticaPrivacidadeModal = () => {
  const [visible, isVisible] = useState(false)
  const [cookies, setCookie] = useCookies(['versionPolicy'])

  useEffect(() => {
    if (cookies.versionPolicy !== versaoPrivacidade) {
      setTimeout(() => {
        isVisible(true)
      }, 5000)
    }
  }, [visible, cookies.versionPolicy])

  const link = (text: string) => {
    return (
      <a
        href="https://atende.epocacosmeticos.com.br/hc/pt-br/articles/360034173452-Pol%C3%ADtica-de-Privacidade"
        className="text-[#ffadd7] underline"
      >
        {text}
      </a>
    )
  }

  const generateCookie = () => {
    const expires = new Date()

    expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000)
    setCookie('versionPolicy', versaoPrivacidade, { path: '/', expires })
  }

  const handleClick = () => {
    window.localStorage.setItem('primeiro_aceite_privacidade', 'true')
    generateCookie()
    isVisible(false)
  }

  const renderText = () => {
    if (window.localStorage.getItem('primeiro_aceite_privacidade')) {
      return (
        <p>
          Oi! Nossa política de privacidade foi atualizada. Ao continuar, você
          concorda com {link('nossos termos')}.
        </p>
      )
    }

    return (
      <p>
        Cookies: a gente guarda estatísticas de visitas para melhorar sua
        experiência de navegação. Ao continuar, você concorda com nossa{' '}
        {link('política de privacidade')}.
      </p>
    )
  }

  return (
    <>
      {visible && (
        <div
          className="top-[173px] z-10 lg:top-auto lg:bottom-0 bg-[rgba(51,51,51,0.9)] fixed w-full"
          data-testid="politica-modal"
        >
          <div className="w-full lg:flex lg:justify-between container text-white pl-[20px] pr-[20px] pb-[15px] pt-[15px]">
            <div>{renderText()}</div>
            <div className="pt-[10px] lg:pt-0 text-right">
              <button
                className="bg-transparent whitespace-nowrap font-bold rounded-[5px] pb-1 pt-1 pl-[15px] pr-[15px] text-[13px]"
                style={{ border: '1px solid #ccc' }}
                data-testid="button-privacidade-modal"
                onClick={handleClick}
              >
                CONCORDAR E FECHAR
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
