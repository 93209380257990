import { draftjsToHtml } from 'src/utils'
import cmsFooter from 'public/cmsFooter.json'

import type { FooterType } from './Footer'
import { FooterAppMobile } from './FooterAppMobile'
import { FooterColumns } from './FooterColumns'
import { FooterSelos } from './FooterSelos'

export const Footer = () => {
  const dadosCMS: FooterType = cmsFooter.data[0].sections[0].data
  const descricao = draftjsToHtml(dadosCMS.descricao.content)

  return (
    <>
      <footer className="footer">
        <div className="w-full bg-black text-white">
          <div className="lg:flex container">
            <FooterColumns dadosCMS={dadosCMS} />
            <div className="lg:pt-[5px] xl:pl-[10px]">
              <div className="lg:mt-2 mb-2 lg:pr-0 pt-[8px] pr-[12px] pb-[8px] pl-[12px] leading-[40px]">
                <a
                  href={dadosCMS?.pagamento?.link ?? '#'}
                  title="Forma de pagamento"
                >
                  <span>Formas de Pagamento</span>
                  <ul className="lg:mt-[5px] lg:mb-4 flex-wrap flex">
                    {dadosCMS?.pagamento?.allItems?.map((forma, index) => {
                      return (
                        <li
                          key={index}
                          className="lg:mr-[8px] xl:mr-[15px] mr-2 mt-2 w-[45px] h-[29px]"
                        >
                          <img
                            src={forma.imagem}
                            alt="Forma de pagamento"
                            width={45}
                            height={29}
                            className="h-auto"
                          />
                        </li>
                      )
                    })}
                  </ul>
                </a>
              </div>
              <div className="lg:flex">
                <div className="lg:mr-[20px] mb-2 pr-[12px] pb-[8px] pl-[12px]">
                  <span className="lg:leading-[37px]">Vem ficar pertinho</span>
                  <ul className="mb-3 flex">
                    <li className="lg:mr-[15px] mr-2 mt-2">
                      <a
                        href={dadosCMS?.redesSociais?.facebook}
                        title="Facebook"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAF0SURBVHgB7ZfhUcJAEIVfMhQQO6AD6cDYAVYgVqAdgBU4VmBKoANDBdKBZwVCBfEtuWi8uWUYcsnxI9/McnC5mf3Ibi5JAoeqqjIOOSNDfxjGNkmSnbqCIkvGdzUMkmfpk8gYH1UcJO+hCqn1EcMZ4jCz+ZHQasrxE/G5mvBjjv6QBjV2bJjacFmITB/lKRnPvFpK30FW4x31FdvmeoLwiMQKZ5AiLMW5IkJomVd0IGSZDM/K1neAPZLjf9NmvcvAL/LC4QknELJMRpk/SUQI3TOdGGU0ujTwivHV+m2UdQ+eOWnqLKTMRtvu23BN4c7xCnvzrR28TBRR74UxekZ9nI0ho56ZLj0ztw9mDcbXQ1yzcKZu0IPMo/O7QP0c4+JtVh/jpqcxymiMMhois8NlsBeZNS6DMrW7pkFcZPdeNz1zh3jlkry38uUgY18xZMJgWIzkZX4Z/+5NIsSbmgjljHv4X86PsT+S0EX+/Ab1G+hvRX4AqKhFzf450XUAAAAASUVORK5CYII="
                          alt="Facebook"
                          width={35}
                          height={35}
                          loading="lazy"
                        />
                      </a>
                    </li>
                    <li className="lg:mr-[15px] mr-2 mt-2">
                      <a
                        href={dadosCMS?.redesSociais?.instagram}
                        title="Instagram"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAK4SURBVHgBvVfRcdswDIVy/q8yQblBlQ2UCepMUHmCZgM7E8Qb2CO0E9gbRJ1AzAR2J2CIELo80aRIJ7LfHSyKhIhHEAToghIwxij76OUzOIrooig0nQtLoLaytnIw06KzspEFJkmUVp7NdfDs2y+QiH3srFQBnlqkd3kuFDxVYLy1cm+3bzinuA+xM267SpoA4vW5cVuF2PiKjaewoguC5/fs1Ti4izKdxrgSAvOIzR0qIhRNCJkfT2Uj/bVnt7yhYcC2n8kFiQXUVjDufvKPtbO3j1fUYzIKOjRlGjcuD3W8JNvVyepezGke0d7nf6H9Am3lB9Oa0kRy89ASvqmFZOPNtQb91YyGnjkmiHBwN5QHXqSy27GQLdkHdNDee8xkwbiM2UCXtrKwcmuNcfK8lXcNOo0JZNoIvrGRrR/pASKVtwWjx9+cJtA6oof5bZPrmSW0ufouxpRlfB/5PookGePKwRy6FpSHJ2hXJqOs5HgG89BRgjEJ0esDlImogFp5LhlEe6a+pohhmoCMomkxSCU5ZDS0lcm8UkgWHpQa+ioZqVVYQx4pD7+grU8uUAHkbtMW2kvOO2PKMr6CrifKQC4Zrlka3rkgPgZIlNK/g272ypbCUPgyGxvswS62Rh7ESB8zXDB/k4uFfgvmNDwh3H9PmZhR5gXbEmqt8TshpOiDvIp8oq08nHE/er3xyHyncUKa3Eq3FF8E93OM3PECaBw/8IU9o+G9SnzcE+KSsJACyN+UQoKNtzknR4Bb2voV+WAm+muSggQ7ouoH8MK8oiuATx3Y7HBg5XknuV1fJFJFHSAu6zxCDV0AcqFCIp2UjsF/bfYG5hGGJndJ+kcf/7XPhZI5+eQE81Dw1In7OnMdpMPBuP9EG3M5HGT+k1NbjJEil0NqcsmwBLfnQsPzP7k89CeWh94A/sFNvOjcOqkAAAAASUVORK5CYII="
                          alt="Instagram"
                          width={35}
                          height={35}
                          loading="lazy"
                        />
                      </a>
                    </li>
                    <li className="lg:mr-[15px] mr-2 mt-2">
                      <a
                        href={dadosCMS?.redesSociais?.twitter}
                        title="Twitter"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHzSURBVHgB3VeBVcJADE19DFA3OCagbFA3wAksG+gE4AS6QdlANqBOIE7Q20Cd4Ey49FF4NBdLaXn+9z6lXHr3aXJJLoIjOOdivKTIGC4Hi9xGUfTdaIFCFsgv1w9oncUpETHyww0DWnfnhRvWQwoTGAYJrw8RqjJ4LWF43NKbmcF1ICMxQ7nnGJMbuCL8azEFco4cI6f83dbG7dH9IXA3rYQc8IgsnQ6vDfNTDlsiU9q5wvN5SEzME5RORjA18DwbWrRhjlx0E9UOpOVXvhJMnyEAnofcljbZiGKcT4iVqDmLKk6YWgiA59ogDbQRg8jqNyiIKu0d+AB9Qq6RW9AhlYTsIMQMVVUqYp0kRec3gwQxZsg1U+gOJmQgiaHoT8g10A0mIYNQzLxAB+DgTUN2ITGUqN6qXXUGUo2RphxQAGdnClpojDRiDHLNSevPcL7PNRpbbaFs5Sp+Zqm114oxyJLriqozrGVcNdq0EMGtrkn9pzASxgrY15xP5Eo8dO2F0JvLoc0h0MktRK6NFef7lY1rj3wUWCMDv63JNe/Ax1IeM/zvKbPOoIPjcEhMhQR6OEVQAAfjoCf8kJg1XAeKiD6d72ENDAeLO3Vc5Zl7GM5dtC51jz7pcc9CP1joF5bWrepeVB+p9R0PcFm3VaniIJH+AkL887pi6f64AAAAAElFTkSuQmCC"
                          alt="Twitter"
                          width={35}
                          height={35}
                          loading="lazy"
                        />
                      </a>
                    </li>
                    <li className="lg:mr-[15px] mr-2 mt-2">
                      <a
                        href={dadosCMS?.redesSociais?.youtube}
                        title="Youtube"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjEAQAAADkQKeRAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfmBBoRETXeY21wAAAEWUlEQVRYw82XbWwUVRSGnzuz2213S5dK0YYvFQg2sYgmfEgwmhiLqYhGUhX5ofJL/ESiwR9i0QbRkAAaChISlQgESWr4UQpEQkwIiIiJNkCMWAJSqFXKx0pbaHd2Xn8MdLsss262ydaTnGRy7znnfee9Z+69Y+SWLoA3bMzchTBuLARt8maOBS2b0NYYrNlp5C59FfNiG4xqhUAJsDd/ZHgEnAvQug992WykX4/D+HkQOHwtwAWsPBDph+NUQMujlrc0gZJ+QfkgcgNOYDSM/8xIUp4V8VWoP5lBt8FQYsBkBqpeVvmBDHM9QOjas0kOJ4qg9w9wjoO2gY6DpoE5BGYCmGchMAEKbgf7Snp+St0U8+sZxyOqhdB5Fs61Q9dl1FWJid0CsR/R5QZwQpjEOnAbwKpB9isQ6MEMqYHoVBS9gIkcg8hkGJ6A4giYeiAB2NmSuWbtFajxGdjYBkdKoXsehgdABxA/AZcwxIArQBEiCgzFMBnMDMR+CG+De7bAC19gZi+B8kP+i+lrsbHSyt1yw7vkWt1yWS2XkFzocyU9ra4XE/LybOSGm6RVNVJspR9iBjIHf5NbvSgFPMXNE5JplmjKQOYGr14uHWz3Q8zwNXXshdMF19vPWEn3rPJ1eHABlD8FoATITTZqSnzfaOsy6BiWwzJtHim37Hf/t545R9pxTNr+mjSrRE7wcQnkePPpOCB3uC1tnpqDMt2fYC5P8Z8PVMEdQZjtwvJC7OXzYUoQ21Pipr1k/vkIunNp4PqmzM35WIX0S28y/tJIaef70kud0qjNEkf75yabvb4tB2XcFV4VMpjpt/7RM1BdBXM+htvWgtma+gZ9dT/1q5aBjLXYw8tERueTzxfvgh0vwzeV8NcJ0NOpvK8/2N/lQKagG0JDMzBZCIFTkFgEzRfQhlmw5HvYMBfO/A3cm54SWgbBAzmQCb+Jhhz2n3f2wKk4NMbh3XHovS5oLqYXEBjjeaqQJUshPMNfaF9rXCf37sXXN6yUHBfkTtwjPTRdKi+Q0veZ9HiQW1kkNcZzaOCyh2GMd6qbm239R9fAvvXQvh3A2GCsZJumxPdRHL0eyq7moExshLSqVG7kHckOSnwoEfI2ryzOJm885OXZQbmRt6XVT0qxmB9iplO7B9rrYMdM2PgnNJeiK8UYZoJ+QPwMdAAx4CpQCESBMgz3gbkf8S2mqBMmnYf5+2HWaigP+eD9532mFjpvhXMXoasOddVgYiMgthh11UK8EJw60BgwpyFQC8GrmEgdRFegaBsm0gCRWhg+DYqPgHmLHO4zvUBB+nAiDPGR4NwJbg3oCOh5MF+BmQhWAwROQvAs2N3Z183+7yClDXOwrPKzvZAPhEjW+f/bXxU35yoDsz5cC+JNwHQGTyULqAInYkFLBTgtg6BQPxznBLRMtNDXu6H1JDjhPCtkeXhOFFrr0ZbdAVizC2kS5rnPYfwwCOaJC0D8A2g5j7ZGYG31v8uBXq8qdtl2AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTA0LTI2VDE3OjE3OjM4KzAwOjAwMkcxoAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0wNC0yNlQxNzoxNzozOCswMDowMEMaiRwAAAAASUVORK5CYII="
                          alt="Youtube"
                          width={35}
                          height={35}
                          loading="lazy"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="baixeApp">
                  <span className="leading-[37px]">Baixe o nosso App</span>
                  <div className="mt-1 flex pr-[12px]">
                    <a
                      href={dadosCMS?.baixeApp?.android?.link}
                      title="Disponível no Google Play"
                      target="_blank"
                      rel="noreferrer"
                      className="mr-[15px] w-[106px] h-[37px]"
                    >
                      <img
                        src={dadosCMS.baixeApp.android.imagem}
                        alt="Google Play"
                        width={106}
                        height={37}
                        className="bg-white h-full w-full"
                        loading="lazy"
                      />
                    </a>
                    <a
                      href={dadosCMS?.baixeApp?.ios?.link}
                      title="Baixar na App Store"
                      target="_blank"
                      rel="noreferrer"
                      className="w-[106px] h-[37px]"
                    >
                      <img
                        src={dadosCMS.baixeApp.ios.imagem}
                        width={106}
                        height={37}
                        alt="App Store"
                        className="bg-white h-full w-full"
                        loading="lazy"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterSelos />
        <div
          className="descricao container lg:text-center lg:pl-[20px] lg:pr-[20px] lg:pb-[10px] text-[12px] text-left mt-3"
          dangerouslySetInnerHTML={{ __html: descricao }}
        />
        <FooterAppMobile />
      </footer>
    </>
  )
}
