import type { PropsWithChildren } from 'react'
import { useContext, lazy, Suspense } from 'react'

import { Footer } from 'src/components/common/Footer'
import Navbar from 'src/components/common/Navbar'
import { useUI } from 'src/sdk/ui/Provider'

import { PoliticaPrivacidadeModal } from './components/common/PrivacidadePoliticaModal'
import { ModalBox } from './components/molecule/ModalBox'
import { ModalLoader } from './components/molecule/ModalLoader'
import { StoreContext } from './providers/StoreProvider'
import { addUserToOrderform, checkUtmsAndStore } from './utils'

const CartSidebar = lazy(() => import('src/components/cart/CartSidebar'))

function Layout({ children }: PropsWithChildren | any) {
  const { cart: displayCart } = useUI()
  const { modalFavOpen, setModalFavOpen, contentModalFav } =
    useContext(StoreContext)

  checkUtmsAndStore()
  addUserToOrderform()

  return (
    <>
      <Navbar />

      <main>{children}</main>

      <PoliticaPrivacidadeModal />

      <Footer />
      {displayCart && (
        <Suspense fallback={null}>
          <CartSidebar />
        </Suspense>
      )}

      <ModalBox
        data-modal-favoritos="true"
        opened={modalFavOpen}
        toggle={setModalFavOpen}
      >
        <>{contentModalFav}</>
      </ModalBox>

      <ModalLoader />
    </>
  )
}

export default Layout
