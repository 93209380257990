import { useContext, useEffect, useState } from 'react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { StoreContext } from 'src/providers/StoreProvider'
import slugify from 'slugify'
import type { AnchorHTMLAttributes } from 'react'
import styles from './arrow-icon.module.scss'

export interface ToggleSubMenuMobileProps {
  name: string
  onClickLink?: AnchorHTMLAttributes<HTMLAnchorElement>['onClick']
}

export const ToggleSubMenuMobile = ({ name }: ToggleSubMenuMobileProps) => {
  const { subMenuMobileToggle, submenuMobileOpened } = useContext(StoreContext)
  const [arrowUp, setArrowUp] = useState<boolean>(false)

  const menuActive = () => {
    subMenuMobileToggle(name)
    setArrowUp(!arrowUp)
  }

  useEffect(() => {
    setArrowUp(name === submenuMobileOpened)
  }, [name, submenuMobileOpened])

  return (
    <button
      data-testid="toogleSubmenuMobile"
      data-reference={`submenu_${slugify(name.toLowerCase())}`}
      className={styles.fsArrowIcon}
      onClick={menuActive}
      aria-label={`Abrir menu ${name}`}
    >
      <ChevronRightIcon
        className={`w-[24px] mr-1 transition-all duration-300 ${
          arrowUp ? 'rotate-90' : ''
        }`}
      />
    </button>
  )
}
