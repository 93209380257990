import { useContext, useEffect, useState } from 'react'
import { useSession } from 'src/sdk/session'
import { utils, tokenjwtkey } from 'store.config'
import { getCsrf } from 'src/utils'
import { sign } from 'jsonwebtoken'
import { StoreContext } from 'src/providers/StoreProvider'

interface AddFavoritosProps {
  csrf: string
  userId: string
  data: Array<{
    skuId: string
    productId: string
  }>
}

export async function addFavoritos({ csrf, userId, data }: AddFavoritosProps) {
  const url = `${utils.baseUrl}/favoritos`

  const favoritosRequest = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      csrf,
      userId,
    },
    body: JSON.stringify(data),
  })

  const favoritosData = await favoritosRequest.json()

  setFavToStorage(favoritosData)

  return favoritosData ?? null
}

export async function removeFavoritos({
  csrf,
  userId,
  data,
}: AddFavoritosProps) {
  const url = `${utils.baseUrl}/favoritos`

  const favoritosRequest = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      csrf,
      userId,
    },
    body: JSON.stringify(data),
  })

  const favoritosData = await favoritosRequest.json()

  decrementFavToStorage(data)

  return favoritosData ?? null
}

export function setFavToStorage(response: any) {
  const allFavs = response.all_favs
  const countAllFavs = response.count_all
  const stringToStorage = JSON.stringify(allFavs)

  window.localStorage.setItem('_favoritos', stringToStorage)
  setCountStorageFav(String(countAllFavs))
}

export function incrementFavToStorage(fav: FavsStorageProps[]) {
  const data = fav[0] // seleciona apenas o primeiro item (sempre vem apenas 1)
  const prodId = data.productId
  const localFav = getFavsFromStorage() // pega o que está no local storage
  const _filtered = localFav.filter((item) => item.productId !== prodId) // filtra para não ter duplicatas

  _filtered.push(data)
  const totalFav = _filtered.length
  const stringToStorage = JSON.stringify(_filtered)

  window.localStorage.setItem('_favoritos', stringToStorage)
  setCountStorageFav(String(totalFav))
}

export function decrementFavToStorage(fav: FavsStorageProps[]) {
  const data = fav[0] // seleciona apenas o primeiro item (sempre vem apenas 1)
  const skuId = data.skuId
  const localFav = getFavsFromStorage() // pega o que está no local storage
  const _filtered = localFav.filter((item) => item.skuId !== skuId) // filtra para não ter duplicatas

  const totalFav = _filtered.length
  const stringToStorage = JSON.stringify(_filtered)

  window.localStorage.setItem('_favoritos', stringToStorage)
  setCountStorageFav(String(totalFav))
}

export async function getFavsToStorage(csrf: string, userId: string) {
  const now = new Date().getTime()

  try {
    if (typeof window !== 'undefined') {
      const pullFavs = window.localStorage.getItem('_pullfavs') || '0'
      const hasFav = !!window.localStorage.getItem('_favoritos')

      if (!hasFav || parseInt(pullFavs, 10) < now) {
        const url = `${utils.baseUrl}/favoritos/skus`
        const favoritosRequest = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            csrf,
            userId,
          },
        })

        const favoritosData = await favoritosRequest.json()
        const totalFav = favoritosData.length
        const stringToStorage = JSON.stringify(favoritosData)

        // adicionando informação no localstorage
        window.localStorage.setItem('_favoritos', stringToStorage)
        // setando um timestamp com 5min a frente para evitar muitas requisições
        window.localStorage.setItem(
          '_pullfavs',
          (now + 5 * 60 * 1000).toString()
        )

        return totalFav
      }

      const _favs = window.localStorage.getItem('_favoritos') ?? '[]'
      const favStorage = JSON.parse(_favs)

      return favStorage.length
    }
  } catch (error) {
    console.error(error)
  }

  return 0
}

export async function useInitFavPersion() {
  const [countFav, setCountFav] = useState(0)
  const { favoritesCount } = useContext(StoreContext)
  const { person } = useSession()

  try {
    if (typeof window !== 'undefined') {
      if (person) {
        // se tiver dado de person, adiciona no localstorage
        const personStr = JSON.stringify(person)

        const personToken = sign(personStr, tokenjwtkey)

        window.localStorage.setItem('person', personToken)
      } else {
        // se não tiver remove o dado anterior
        window.localStorage.removeItem('person')
        window.localStorage.removeItem('_favoritos')
        window.localStorage.removeItem('_pullfavs')
        window.localStorage.removeItem('_countFav')
      }
    }
  } catch (error) {
    console.error(error)
  }

  const updateFavInfo = async () => {
    if (person) {
      const userId = person.id
      const csrf = getCsrf(userId, person.email)

      const dataFav = await getFavsToStorage(csrf, userId)

      setCountStorageFav(String(dataFav))
      setCountFav(dataFav)
    }
  }

  useEffect(() => {
    updateFavInfo()
  }, [person, favoritesCount])

  return countFav
}

export function getCountStorageFav() {
  try {
    const count = window.localStorage.getItem('_countFav')

    return count ?? 0
  } catch (error) {
    console.error(error)

    return 0
  }
}

export function setCountStorageFav(count: string) {
  try {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('_countFav', count)
    }
  } catch (error) {
    console.error(error)
  }
}

export interface FavsStorageProps {
  id?: number
  skuId: string
  productId: string
}

export function getFavsFromStorage(): FavsStorageProps[] {
  try {
    if (typeof window !== 'undefined') {
      const _favs = window.localStorage.getItem('_favoritos')

      if (_favs) {
        return JSON.parse(_favs)
      }

      return []
    }

    return []
  } catch (error) {
    return []
  }
}
