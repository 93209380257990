import { ChevronRightIcon } from '@heroicons/react/24/outline'
import SlideOver from 'src/components/ui/SlideOver'
import { mark } from 'src/sdk/tests/mark'
import { useUI } from 'src/sdk/ui/Provider'
import { useFadeEffect } from 'src/sdk/ui/useFadeEffect'

import styles from './navbar-slider.module.scss'
import NavLinks from './NavLinks'
import NavLinksFooter from './NavLinksFooter'

function NavbarSlider() {
  const { closeNavbar } = useUI()
  const { fade, fadeOut } = useFadeEffect()

  return (
    <SlideOver
      isOpen
      fade={fade}
      onDismiss={fadeOut}
      size="partial"
      direction="leftSide"
      className={styles.fsNavbarSlider}
      onTransitionEnd={() => fade === 'out' && closeNavbar()}
    >
      <header data-fs-navbar-slider-header>
        <a href="/login" data-fs-navlinks-link>
          <span className={styles.firstLineLogin}>Oie!</span>
          <span className={styles.secondLineLogin}>Vem fazer seu login ;)</span>
        </a>
        <a href="/login" className={styles.divIcon}>
          <ChevronRightIcon
            viewBox="0 0 20 20"
            width={20}
            height={20}
            color="white"
          />
        </a>
        {/* <a href="/login" data-fs-navlinks-link>
          Oie!
        </a>
        <a href="/login" data-fs-navlinks-link>
          Vem fazer seu login ;)
        </a> */}
      </header>
      <div data-fs-navbar-slider-content>
        <NavLinks onClickLink={fadeOut} />
      </div>
      <footer data-fs-navbar-slider-footer>
        <NavLinksFooter onClickLink={fadeOut} />
      </footer>
    </SlideOver>
  )
}

export default mark(NavbarSlider)
