import 'react-image-gallery/styles/css/image-gallery.css'

import '../styles/global/tailwind.css'

import '../styles/global/tokens.scss'
import '../styles/global/typography.scss'
import '../styles/global/layout.scss'
import '../styles/global/components.scss'

// Replace this with your theme style file
import '../styles/themes/epoca-theme.scss'
import '@fancyapps/ui/dist/fancybox.css'

import NextNProgress from 'nextjs-progressbar'
import type { AppProps } from 'next/app'

import Layout from 'src/Layout'
import AnalyticsHandler from 'src/sdk/analytics'
import ErrorBoundary from 'src/sdk/error/ErrorBoundary'
import UIProvider from 'src/sdk/ui/Provider'
import { StoreProvider } from 'src/providers/StoreProvider'

function App({
  Component,
  pageProps,
}: AppProps & { Component: any; pageProps: any }) {
  return (
    <ErrorBoundary>
      <NextNProgress
        color="var(--fs-color-main-0);"
        showOnShallow={false}
        options={{ showSpinner: false }}
      />
      <AnalyticsHandler />
      <StoreProvider>
        <UIProvider>
          <Layout {...pageProps}>
            <Component {...pageProps} />
          </Layout>
        </UIProvider>
      </StoreProvider>
    </ErrorBoundary>
  )
}

export default App
