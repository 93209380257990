export interface AutocompleteListItemProps {
  term: string | undefined | null
  handleClick: () => void
}

export const AutocompleteListItem = ({
  term,
  handleClick,
}: AutocompleteListItemProps) => {
  const link = `/pesquisa?q=${term}`

  return (
    <li>
      <a
        onClick={handleClick}
        href={link}
        title={term ?? ''}
        className="w-full block hover:text-pink hover:bg-rose-50 px-1 py-0.5"
      >
        {term}
      </a>
    </li>
  )
}
