import slugify from 'slugify'

import type { SectionSubmenu } from './SectionMenu'

export interface MenuColProps {
  coluna?: SectionSubmenu[]
}

export const MenuCol = ({ coluna }: MenuColProps) => {
  return (
    <>
      <ul
        role="menu"
        className="container mx-auto flex-1 ml-2
                    xl:py-2 xl:ml-0
                    "
      >
        {coluna?.map((item, index) => {
          return (
            <li
              className="mx-2"
              key={`${slugify(item.nome)}-${index}`}
              role="none"
            >
              <a
                role="menuitem"
                className="py-1 inline-block hover:text-pink transition-all"
                href={item.url ? item.url : '#'}
                tabIndex={0}
              >
                {item.nome}
              </a>
            </li>
          )
        })}
      </ul>
    </>
  )
}
