import { useContext, useEffect, useState } from 'react'
import slugify from 'slugify'

import { StoreContext } from 'src/providers/StoreProvider'

import { SubmenuMarcas } from '../SubmenuMarcas'
import { MenuCol } from './MenuCol'
import type { SectionSubmenu } from './SectionMenu'
import { ShelfMenu } from './ShelfMenu'

export interface SubmenuProps {
  name: string
  items: {
    coluna1?: SectionSubmenu[]
    coluna2?: SectionSubmenu[]
    coluna3?: SectionSubmenu[]
  }
  prateleira?: {
    nome?: string
    skus?: string
  }
  currentOpened: boolean
}

export const Submenu = ({
  name,
  items: { coluna1, coluna2, coluna3 },
  prateleira,
  currentOpened,
}: SubmenuProps) => {
  const { submenuMobileOpened, accessibleMenuFocus } = useContext(StoreContext)

  const [opened, setOpened] = useState<boolean>(false)
  // controle de menu sendo aberto via TAB
  const [accessibleMenuOpen, setAccessibleMenuOpen] = useState<boolean>(false)

  useEffect(() => {
    setOpened(name === submenuMobileOpened)
  }, [name, submenuMobileOpened, accessibleMenuFocus])

  useEffect(() => {
    setAccessibleMenuOpen(name === accessibleMenuFocus)
  }, [name, accessibleMenuFocus])

  const classOpened = ' xl:!visible xl:!max-h-[2000px]'
  const classClosed = ' xl:invisible xl:max-h-0 asd'

  return (
    <>
      <section
        className={`
                ${opened ? 'visible max-h-[2000px]' : 'invisible max-h-0'}
                group-focus:visible
                transition-all duration-300 overflow-hidden bg-gray-light
                xl:fullSubmenu xl:fixed xl:w-full xl:inset-x-0 xl:z-50 xl:bg-gray-light
                xl:transition-none
                ${accessibleMenuOpen ? 'xl:!visible xl:!max-h-[2000px]' : ''}
                ${currentOpened ? classOpened : classClosed}
              `}
        id={`submenu_${slugify(name.toLowerCase())}`}
      >
        <div className="container mx-auto flex">
          {name.toLowerCase() === 'marcas' ? (
            <div className="menus flex flex-1 flex-col xl:flex-row">
              {coluna1 ? <MenuCol coluna={coluna1} /> : ''}
              <SubmenuMarcas />
            </div>
          ) : (
            <div className="menus flex flex-1 flex-col xl:flex-row">
              {coluna1 ? <MenuCol coluna={coluna1} /> : ''}
              {coluna2 ? <MenuCol coluna={coluna2} /> : ''}
              {coluna3 ? <MenuCol coluna={coluna3} /> : ''}
            </div>
          )}

          {prateleira?.nome && prateleira?.skus && prateleira?.skus !== '' ? (
            <ShelfMenu content={prateleira} />
          ) : (
            ''
          )}
        </div>
      </section>
    </>
  )
}
