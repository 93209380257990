import axios from 'axios'
import { convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { useEffect, useState } from 'react'
import { verify } from 'jsonwebtoken'

import type {
  categoryPageProps,
  OptionsSearchOrderProps,
} from 'src/providers/StoreProvider'
import type {
  Filter_FacetsFragment,
  IStoreSelectedFacet,
} from '@generated/graphql'
import { useCart } from 'src/sdk/cart'
import { useSession } from 'src/sdk/session'
import { getTokenOptin } from './Optin'
import { tokenjwtkey } from 'store.config'

export const vtexSearchApi = async (fqQuery: string) => {
  const url = `/api/catalog_system/pub/products/search?${fqQuery}`

  return (await axios.get(url)).data
}

export const formatPrice = (n: number) => {
  return new Intl.NumberFormat('pb-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(n)
}

export const getCsrf = (userId: string, email: string) => {
  const uid = `asdasd${userId}`

  const ha = `hdj2kqw${uid}euilFasdh=$ua${userId}uyr==`

  const header = JSON.stringify({
    ha,
    time: uid,
  })

  const data = {
    userId,
    user: email,
    userType: 'F',
    uid,
  }

  const dataStr = JSON.stringify(data)

  try {
    // guardando o token para ser usado em todas as requisições
    return `${window.btoa(header)}.${window.btoa(dataStr)}.${window.btoa(
      ha + uid
    )}`
  } catch (error) {
    return ''
  }
}

export const draftjsToHtml = (jsonRawString: string): string => {
  if (typeof jsonRawString !== 'string') {
    return 'null'
  }

  if (jsonRawString.charAt(0) !== '{') {
    return jsonRawString
  }

  const raw = JSON.parse(jsonRawString)

  // // convertendo o conteudo do draft js para contentState do editor
  const rawState = convertFromRaw(raw)
  // // convertendo o estado para html
  const textHtml = stateToHTML(rawState)

  return textHtml
}

export interface PiscouPerdeuResponse {
  timespamp: number
  final_date: string
  skuDia?: string
  skusDia?: string
  produto?: string
  produtos?: string
}

export const getItemsPiscouPerdeu = async (
  qtdItens: string
): Promise<PiscouPerdeuResponse> => {
  let url = ''

  if (qtdItens === '1 item') {
    url = 'https://utils.epocacosmeticos.net.br/tools/api/piscou-perdeu/single'
  } else {
    // 2 e 4 itens usam o mesmo endpoint
    url = 'https://utils.epocacosmeticos.net.br/tools/api/piscou-perdeu/multi'
  }

  const { data } = await axios.get(url)

  return data
}

export const searchOrderDecode = (sort: string) => {
  // { value: 'relevancia', label: 'Relevancia' },
  // { value: 'product_effectiveprice_cents desc', label: 'Maior Preço' },
  // { value: 'product_effectiveprice_cents asc', label: 'Menor Preço' },
  // { value: 'product_name asc', label: 'A-Z' },
  // { value: 'product_name desc', label: 'Z-A' },
  // { value: 'product_release_date desc', label: 'Data de Lançamento' },
  switch (sort) {
    case 'product_effectiveprice_cents desc':
      return 'maiorpreco'

    case 'product_effectiveprice_cents asc':
      return 'menorpreco'

    case 'product_name asc':
      return 'az'

    case 'product_name desc':
      return 'za'

    case 'product_release_date desc':
      return 'datalancamento'

    default:
      return sort
  }
}

export const searchOrderEncode = (
  paramUrl: string
): OptionsSearchOrderProps => {
  switch (paramUrl) {
    case 'maiorpreco':
      return {
        value: 'product_effectiveprice_cents desc',
        label: 'Maior Preço',
      }

    case 'menorpreco':
      return { value: 'product_effectiveprice_cents asc', label: 'Menor Preço' }

    case 'az':
      return { value: 'product_name asc', label: 'A-Z' }

    case 'za':
      return { value: 'product_name desc', label: 'Z-A' }

    case 'datalancamento':
      return { value: 'product_release_date desc', label: 'Data de Lançamento' }

    default:
      return { value: 'relevancia', label: 'Relevancia' }
  }
}

export const useOnScreen = (ref: React.RefObject<any>) => {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  )

  useEffect(() => {
    observer.observe(ref.current)

    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect()
    }
  }, [])

  return isIntersecting
}

export const useIsInViewport = (ref: React.RefObject<any>) => {
  const [inView, setInview] = useState(false)

  useEffect(() => {
    const rect = ref.current.getBoundingClientRect()
    const inViewPort =
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || window.document.documentElement.clientHeight) &&
      rect.right <=
        (window.innerWidth || window.document.documentElement.clientWidth)

    setInview(inViewPort)
  }, [])

  return inView
}

// eslint-disable-next-line func-names
export const groupByArray = function (xs: any[], key: string) {
  return xs.reduce(function (rv, x) {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi

    ;(rv[x[key]] = rv[x[key]] || []).push(x) // NOSONAR

    return rv
  }, {})
}

// eslint-disable-next-line func-names
export const namefilterAjust = function (str: string) {
  // eslint-disable-next-line func-names
  return str.replace(/(&#(\d+);)/g, function (_, __, charCode) {
    return String.fromCharCode(charCode)
  })
}

export const filterCategoryFacets = (
  facets: Filter_FacetsFragment[],
  categoryPage: categoryPageProps | null
) => {
  return facets.filter((item: { key: string }) => {
    if (categoryPage?.key === 'productClusterIds') {
      return item.key !== 'productClusterIds'
    }

    if (categoryPage?.key === 'category-1') {
      return item.key !== 'category-1' && item.key !== 'productClusterIds'
    }

    if (categoryPage?.key === 'category-2') {
      return (
        item.key !== 'category-1' &&
        item.key !== 'category-2' &&
        item.key !== 'productClusterIds'
      )
    }

    if (categoryPage?.key === 'category-3') {
      return (
        item.key !== 'category-1' &&
        item.key !== 'category-2' &&
        item.key !== 'category-3' &&
        item.key !== 'productClusterIds'
      )
    }

    return true
  })
}

export const filterSelectedFacets = (
  facets: IStoreSelectedFacet[],
  categoryPage: categoryPageProps | null
) => {
  return facets.filter((item: { key: string }) => {
    if (categoryPage?.key === 'productClusterIds') {
      return item.key !== 'productClusterIds'
    }

    if (categoryPage?.key === 'category-1') {
      return item.key !== 'category-1' && item.key !== 'productClusterIds'
    }

    if (categoryPage?.key === 'category-2') {
      return (
        item.key !== 'category-1' &&
        item.key !== 'category-2' &&
        item.key !== 'productClusterIds'
      )
    }

    if (categoryPage?.key === 'category-3') {
      return (
        item.key !== 'category-1' &&
        item.key !== 'category-2' &&
        item.key !== 'category-3' &&
        item.key !== 'productClusterIds'
      )
    }

    if (categoryPage === null) {
      return item.key !== 'productClusterIds'
    }

    return true
  })
}

export const selectedFacetsNamesAjust = (name: string) => {
  let returnName = ''

  switch (name) {
    case 'category-1':
      returnName = 'Departamento'
      break

    case 'category-2':
      returnName = 'Categoria'
      break

    case 'category-3':
      returnName = 'Subcategoria'
      break

    case 'brand':
      returnName = 'Marca'
      break

    default:
      returnName = name
      break
  }

  return returnName.replace(/-/g, ' ')
}

export const setParamUrl = (key: string, value: string) => {
  const queryParams = new URLSearchParams(window.location.search)

  queryParams.set(key, value)
  window.history.replaceState(null, '', `?${queryParams.toString()}`)
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const productRates = (arr: any[]) => {
  const returnArr: any = {}

  arr.forEach((item) => {
    const code = item.product_code

    returnArr[code] = item
  })

  return returnArr
}

export const order = (a: any, b: any) => {
  return a.nome > b.nome ? 1 : b.nome > a.nome ? -1 : 0
}

export const toSlug = (str: string) => {
  str = str.toLowerCase()
  str = str.replace(/[^a-z0-9 -]/g, '-')
  str = str.replace('-and-', '---')

  return str
}

export const getUrlHash = () => {
  return window?.location?.hash?.substring(1)
}

export const setStorageRcs = (key: string, rcs: string) => {
  try {
    if (typeof window !== 'undefined') {
      const _key = `rcs_rich_${key}`

      window.localStorage.setItem(_key, rcs)
    }
  } catch (err) {
    console.warn(err)
  }
}

export const getStorageRcs = (key: string) => {
  try {
    if (typeof window !== 'undefined') {
      const _key = `rcs_rich_${key}`

      return window.localStorage.getItem(_key)
    }

    return ''
  } catch (err) {
    console.warn(err)
  }

  return ''
}

export function setCookie(cname: string, cvalue: string, time: number) {
  if (typeof window !== 'undefined') {
    const d = new Date()

    d.setTime(d.getTime() + time)
    const expires = `expires=${d.toUTCString()}`

    document.cookie = `${cname}=${cvalue};${expires};path=/`
  }
}

export function getCookie(cname: string) {
  if (typeof window !== 'undefined') {
    const name = `${cname}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]

      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }

      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
  }

  return ''
}

export const validEmail = (email: string) => {
  const regex = /\S+@\S+\.\S+/

  return regex.test(email)
}

export default async function getSkuByProductId(productId: string) {
  try {
    const url = `/api/vtex/search/?fq=productId:${productId}`

    const request = await fetch(url, {
      method: 'GET',
    })

    const requestData = await request.json()
    const skusReturn: any[] = []

    requestData[0].items.forEach((item: any) => {
      skusReturn.push({
        sku: item.itemId,
        name: item.name,
      })
    })

    return skusReturn
  } catch (error) {
    return []
  }
}

export function getPersonStorage(): any {
  if (typeof window !== 'undefined') {
    const _personStorage = window.localStorage.getItem('person')

    if (_personStorage) {
      return verify(_personStorage, tokenjwtkey)
    }

    return ''
  }

  return ''
}

export const getStorageTokenOptin = (key: string) => {
  try {
    if (typeof window !== 'undefined') {
      if (!window.localStorage.getItem(key)) {
        getTokenOptin().then((response) => {
          window.localStorage.setItem(key, response.data.token)
        })
      }
    }
  } catch (err) {
    console.warn(err)
  }

  return ''
}

export function getYoutubeVideoId(url: string) {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/

  const match = url.match(regExp)

  return match && match[7].length === 11 ? match[7] : false
}

export interface MktData {
  coupon?: string
  utmCampaign?: string
  utmMedium?: string
  utmSource?: string
  utmiCampaign?: string
  utmiPart?: string
  utmiPage?: string
}

export async function clearMessagesCart(cartId: string) {
  const urlClearMsg = `/api/checkout/pub/orderForm/${cartId}/messages/clear`

  return fetch(urlClearMsg, {
    method: 'POST',
    body: JSON.stringify({}),
    headers: {
      'content-type': 'application/json',
    },
  })
}

export async function updateMktData(cartId: string, sendData: any) {
  const urlUpdateCart = `/api/checkout/pub/orderForm/${cartId}/attachments/marketingData`

  return fetch(urlUpdateCart, {
    method: 'POST',
    body: JSON.stringify(sendData),
    headers: {
      'content-type': 'application/json',
    },
  })
}

export async function updateCartUtms(cartId: string) {
  const MktData: MktData = {}

  const utmSource = getCookie('epc_utm_source')

  if (utmSource) {
    MktData.utmSource = utmSource
  }

  const utmCampaign = getCookie('epc_utm_campaign')

  if (utmCampaign) {
    MktData.utmCampaign = utmCampaign
  }

  const utmMedium = getCookie('epc_utm_medium')

  if (utmMedium) {
    MktData.utmMedium = utmMedium
  }

  const utmiPart = getCookie('epc_utmi_part')

  if (utmiPart) {
    MktData.utmiPart = utmiPart
  }

  const utmipage = getCookie('epc_utmi_page')

  if (utmipage) {
    MktData.utmiPage = utmipage
  }

  const utmiCampaign = getCookie('epc_utmi_campaign')

  if (utmiCampaign) {
    MktData.utmiCampaign = utmiCampaign
  }

  const coupon = getCookie('epc_coupon')

  if (coupon) {
    MktData.coupon = coupon
  }

  const expires = 30 * 1000

  const updateCartUtm = getCookie('_updateCartUtm')

  if (Object.keys(MktData).length > 0 && cartId !== '' && !updateCartUtm) {
    updateMktData(cartId, MktData)
    setCookie('_updateCartUtm', 'true', expires)

    return { expires }
  }

  const clearCartUtm = getCookie('_clearCartUtm')

  if (Object.keys(MktData).length === 0 && cartId && !clearCartUtm) {
    const sendData = {
      marketingTags: [''],
    }

    updateMktData(cartId, sendData)
    setCookie('_clearCartUtm', 'true', expires)

    return { expires }
  }

  return {
    expires: 0,
  }
}

export async function removeCuponOrderform(cartId: string) {
  const MktData: MktData = {}

  await clearMessagesCart(cartId)

  const utmSource = getCookie('epc_utm_source')

  if (utmSource) {
    MktData.utmSource = utmSource
  }

  const utmCampaign = getCookie('epc_utm_campaign')

  if (utmCampaign) {
    MktData.utmCampaign = utmCampaign
  }

  const utmMedium = getCookie('epc_utm_medium')

  if (utmMedium) {
    MktData.utmMedium = utmMedium
  }

  const utmiPart = getCookie('epc_utmi_part')

  if (utmiPart) {
    MktData.utmiPart = utmiPart
  }

  const utmipage = getCookie('epc_utmi_page')

  if (utmipage) {
    MktData.utmiPage = utmipage
  }

  const utmiCampaign = getCookie('epc_utmi_campaign')

  if (utmiCampaign) {
    MktData.utmiCampaign = utmiCampaign
  }

  const urlUpdateCart = `/api/checkout/pub/orderForm/${cartId}/attachments/marketingData`

  await fetch(urlUpdateCart, {
    method: 'POST',
    body: JSON.stringify(MktData),
    headers: {
      'content-type': 'application/json',
    },
  })

  setCookie('epc_coupon', '__', 0)
}

export async function updateCoupon(coupon: string, cartId: string) {
  const MktData: MktData = {}
  const expires = 48 * 60 * 60 * 1000

  await clearMessagesCart(cartId)

  const utmSource = getCookie('epc_utm_source')

  if (utmSource) {
    MktData.utmSource = utmSource
  }

  const utmCampaign = getCookie('epc_utm_campaign')

  if (utmCampaign) {
    MktData.utmCampaign = utmCampaign
  }

  const utmMedium = getCookie('epc_utm_medium')

  if (utmMedium) {
    MktData.utmMedium = utmMedium
  }

  const utmiPart = getCookie('epc_utmi_part')

  if (utmiPart) {
    MktData.utmiPart = utmiPart
  }

  const utmipage = getCookie('epc_utmi_page')

  if (utmipage) {
    MktData.utmiPage = utmipage
  }

  const utmiCampaign = getCookie('epc_utmi_campaign')

  if (utmiCampaign) {
    MktData.utmiCampaign = utmiCampaign
  }

  if (coupon) {
    MktData.coupon = coupon
    const urlUpdateCart = `/api/checkout/pub/orderForm/${cartId}/attachments/marketingData`

    const requestCupon = await fetch(urlUpdateCart, {
      method: 'POST',
      body: JSON.stringify(MktData),
      headers: {
        'content-type': 'application/json',
      },
    })

    const requestData = await requestCupon.json()

    const messages = requestData.messages

    const cupomInvalido = !!messages.filter((msg: any) => {
      const hasCupom = msg.text.search(coupon) >= 0
      const isInvalid = msg.code === 'couponNotFound'

      return hasCupom && isInvalid
    }).length

    if (!cupomInvalido) {
      setCookie('epc_coupon', coupon, expires)
    }

    return requestData
  }

  return null
}

// prettier-ignore
export function checkUtmsAndStore() { // NOSONAR
  if (typeof window !== 'undefined') {
    // capturando queryStrings
    const queryString = window.location.search
    // transformando em obj URLSearchParams para buscar cada uma posteriormente
    const urlParams = new URLSearchParams(queryString)
    // tempo de expirção dos cookies
    const expires = 48 * 60 * 60 * 1000

    const utmSourceParam = urlParams.get('utm_source')

    if (utmSourceParam) {
      // verificando se a utm atual é nova ou é igual a que já está setando
      const utmSource = getCookie('epc_utm_source')

      // se a utm mudou, remove o cookie que impoede o carrinho de atualizar as utms toda hora
      if (utmSourceParam !== utmSource) {
        setCookie('_updateCartUtm', 'true', 0)
      }

      setCookie('epc_utm_source', utmSourceParam, expires)
    }

    const utmCampaignParam = urlParams.get('utm_campaign')

    if (utmCampaignParam) {
      // verificando se a utm atual é nova ou é igual a que já está setando
      const utmCampaign = getCookie('epc_utm_campaign')

      // se a utm mudou, remove o cookie que impoede o carrinho de atualizar as utms toda hora
      if (utmCampaignParam !== utmCampaign) {
        setCookie('_updateCartUtm', 'true', 0)
      }

      setCookie('epc_utm_campaign', utmCampaignParam, expires)
    }

    const utmMediumParam = urlParams.get('utm_medium')

    if (utmMediumParam) {
      // verificando se a utm atual é nova ou é igual a que já está setando
      const utmMedium = getCookie('epc_utm_medium')

      // se a utm mudou, remove o cookie que impoede o carrinho de atualizar as utms toda hora
      if (utmMediumParam !== utmMedium) {
        setCookie('_updateCartUtm', 'true', 0)
      }

      setCookie('epc_utm_medium', utmMediumParam, expires)
    }

    const utmiPartParam = urlParams.get('utmi_part')

    if (utmiPartParam) {
      // verificando se a utm atual é nova ou é igual a que já está setando
      const utmiPart = getCookie('epc_utmi_part')

      // se a utm mudou, remove o cookie que impoede o carrinho de atualizar as utms toda hora
      if (utmiPartParam !== utmiPart) {
        setCookie('_updateCartUtm', 'true', 0)
      }

      setCookie('epc_utmi_part', utmiPartParam, expires)
    }

    const utmipageParam = urlParams.get('utmi_page')

    if (utmipageParam) {
      // verificando se a utm atual é nova ou é igual a que já está setando
      const utmiPage = getCookie('epc_utmi_page')

      // se a utm mudou, remove o cookie que impoede o carrinho de atualizar as utms toda hora
      if (utmipageParam !== utmiPage) {
        setCookie('_updateCartUtm', 'true', 0)
      }

      setCookie('epc_utmi_page', utmipageParam, expires)
    }

    const utmiCampaingParam = urlParams.get('utmi_campaign')

    if (utmiCampaingParam) {
      // verificando se a utm atual é nova ou é igual a que já está setando
      const utmiCampaing = getCookie('epc_utmi_campaign')

      // se a utm mudou, remove o cookie que impoede o carrinho de atualizar as utms toda hora
      if (utmiCampaingParam !== utmiCampaing) {
        setCookie('_updateCartUtm', 'true', 0)
      }

      setCookie('epc_utmi_campaign', utmiCampaing, expires)
    }
  }
}

export async function getTotalsCart(cartId: string) {
  if (!cartId) {
    return null
  }

  const urlUpdateCart = `/api/checkout/pub/orderForm/${cartId}`

  const requestCupon = await fetch(urlUpdateCart, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  })

  const orderform = await requestCupon.json()

  return orderform?.totalizers
}

export async function addUserToOrderform() {
  try {
    const cart = useCart()
    const { person } = useSession()

    // adicionando informacao de usuario para tags do GTM
    if (typeof window !== 'undefined') {
      window._epc_user = {
        data: person,
        logged: !!person,
      }
    }

    // o cookie _userAssigned serve para evitar que a função
    // fique atualizando com frequencia a cada renderização da
    // aplicação, é apenas um fator de prevenção de requisições repetidas.
    const isAssigned = getCookie('_userAssigned')

    // se houver um carrinho criado (orderform)
    // se o usuário existir (se estiver logado)
    // se não existir o cookie _userAssigned, que diz que o orderform já está atualizado
    if (cart.id && person && !isAssigned) {
      const email = person.email
      const urlPost = `/api/checkout/pub/orderForm/${cart.id}/attachments/clientProfileData`

      const dataPerson = {
        email,
      }

      // Post na api passando o email para associar ao carrinho
      const requestProfile = await fetch(urlPost, {
        method: 'POST',
        body: JSON.stringify(dataPerson),
        headers: {
          'content-type': 'application/json',
        },
      })

      await requestProfile.json()
      // setando o cookie para evitar muitas requisições desnecessárias.
      setCookie('_userAssigned', 'true', 2 * 60 * 1000)
    }

    // assim como o _userAssigned, o _userAnonymous serve para evitar muitas
    // requisições desnecessárias/repetidas
    const changeToAnonymous = getCookie('_userAnonymous')
    // verifica se há informação do dermaclub pois com demaclub ativado não anonimizamos o carrinho
    // devido à promoção de cluster
    const emailDermaclub = getCookie('emailDermaclub')

    // Em caso de haver um carrinho e o usuário não estiver logado, o carrinho é transformado
    // em um carrinho anonimo novamente.
    if (cart.id && !person && !changeToAnonymous && !emailDermaclub) {
      const urlGet = `/vtex/checkout/changeToAnonymousUser/${cart.id}`

      await fetch(urlGet)
      setCookie('_userAnonymous', 'true', 2 * 60 * 1000)
    }
  } catch (error) {
    console.warn(error)
  }
}

export async function addEmailToOrderform(email: string, cartId: string) {
  const expires = 48 * 60 * 60 * 1000

  try {
    if (cartId) {
      const urlPost = `/api/checkout/pub/orderForm/${cartId}/attachments/clientProfileData`

      const dataPerson = {
        email,
      }

      // Post na api passando o email para associar ao carrinho
      const requestProfile = await fetch(urlPost, {
        method: 'POST',
        body: JSON.stringify(dataPerson),
        headers: {
          'content-type': 'application/json',
        },
      })

      return await requestProfile.json()
    }

    setCookie('_dermaclubPendentCluster', email, expires)
  } catch (error) {
    console.info(error)
  }
}
