import Image from 'next/image'

import { formatPrice } from 'src/utils'
import type { RichDocProps } from 'src/utils/RichRelevance/RichRelevance'

export interface AutocompleteListProductsItemProps extends RichDocProps {
  last: boolean
  closeFunc: () => void
}

export const AutocompleteListProductsItem = (
  props: AutocompleteListProductsItemProps
) => {
  const closeHandler = () => {
    if (props.last) {
      props.closeFunc()
    }
  }

  return (
    <li className="p-0.5 border-b border-b-pb-100 last:border-0 bg-white mb-0.5 last:mb-0 hover:bg-rose-50">
      <div className="grid grid-flow-col gap-1">
        <div className="w-20 image_shelf_product">
          <a onBlur={closeHandler} href={props.clickUrl}>
            <Image
              src={props.imageId}
              alt={props.name}
              width={80}
              height={80}
              className="xl:max-h-[80px]"
            />
          </a>
        </div>
        <div className="content_shelf_product">
          <a onBlur={closeHandler} href={props.clickUrl}>
            <h5 className="font-semibold text-sm">{props.brand}</h5>
          </a>
          <a onBlur={closeHandler} href={props.clickUrl}>
            <p className="text-xs d-block">{props.name}</p>
          </a>
          <strong className="text-sm">
            {formatPrice(props.salePriceCents / 100)}
          </strong>
        </div>
      </div>
    </li>
  )
}
