import { useEffect, useState } from 'react'

import { MenuDesLogado } from './MenuDesLogado'
import { UserMenu } from './UserMenu'

interface SubMenuAccountProps {
  handlerLast: () => void
  logado: boolean | undefined
}

export const SubMenuAccount = ({
  handlerLast,
  logado,
}: SubMenuAccountProps) => {
  const [renderBlock, setRenderBlock] = useState<JSX.Element>()

  useEffect(() => {
    const handlerBlur = (last: boolean) => {
      if (last) {
        handlerLast()
      }

      return false
    }

    logado
      ? setRenderBlock(<UserMenu handlerBlur={handlerBlur} />)
      : setRenderBlock(<MenuDesLogado handlerBlur={handlerBlur} />)
  }, [handlerLast, logado])

  return <ul role="menu">{renderBlock}</ul>
}
