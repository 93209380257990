import { ShoppingBagIcon } from '@heroicons/react/24/outline'

import Button from 'src/components/ui/Button'
import { useCartToggleButton } from 'src/sdk/cart/useCartToggleButton'

function CartToggle() {
  const btnProps = useCartToggleButton()

  return (
    <Button
      variant="secondary"
      data-fs-button-icon
      data-fs-button-cart
      aria-label={`Cart with ${btnProps['data-items']} items`}
      icon={<ShoppingBagIcon width={32} height={32} />}
      {...btnProps}
    />
  )
}

export default CartToggle
