module.exports = {
  seo: {
    title: 'Época Cosméticos',
    description: 'Beleza com Frete Grátis em até 10x sem juros',
    titleTemplate: '%s | Época Cosméticos',
    author: 'Luiza Labs',
  },

  // Versão da Política de Privacidade
  versaoPrivacidade: 'version_4',

  // define se vai usar o inteligent search ou a busca da rich
  // em caso de 'false' mostra a busca da rich
  vtexSearch: false,

  // Theming
  theme: 'epoca-theme',

  // Ecommerce Platform
  platform: 'vtex',

  tokenjwtkey: 'bwariCH1HUWqZbqG8WuDNg==',

  // Platform specific configs for API
  api: {
    storeId: 'epocacosmeticosqa',
    environment: 'vtexcommercestable',
    hideUnavailableItems: false,
    workspace: process.env === 'development' ? 'develop' : 'master',
  },

  cacheControl: {
    // segundo * minutos * horas
    maxAge: 60 * 15 * 1, // em segundos
    revalidate: 60 * 15 * 1, // em segundos
  },

  // Default session
  session: {
    currency: {
      code: 'BRL',
      symbol: 'R$',
    },
    locale: 'pt-BR',
    channel: '{"salesChannel":"1","regionId":""}',
    country: 'BRA',
    postalCode: null,
    person: null,
    public: null,
    cookie: null,
  },

  rich: {
    baseUrl: 'https://recs.richrelevance.com',
    apiKey: 'c85912f892c73e30',
    apiClientKey: 'd87e7a0748a78f10',
    channelId: 'cb05f4cf5c9ecd3d',
  },

  utils: {
    baseUrl: 'https://utils.epocacosmeticos.net.br/tools/api',
    csrfToken:
      'eyJoYSI6ImluZTNxYWhvOGtuaGRqMmtxdz1ldWlsRiIsInRpbWUiOjE2NjAxNTQ3MzY5NjV9.eyJ1c2VySWQiOiI5YjM2YzcxMC1mYjBjLTQ5YTQtODFhMy1iOTYzNzI5ZTEzZmMiLCJ1c2VyIjoiYmVhdHJpei5yb2RyaWd1ZXNAbHVpemFsYWJzLmNvbSIsInVzZXJUeXBlIjoiRiIsInRpbWUiOjE2NjAxNTQ3MzY5NjV9.aW5lM3FhaG84a25oZGoya3F3PWV1aWxGMTY2MDE1NDczNjk2NQ==',
    userId: '9b36c710-fb0c-49a4-81a3-b963729e13fc',
    optinUrl: 'https://utils.epocacosmeticos.net.br/optin/api',
  },

  // STORE
  production: {
    url: 'https://loja.epocacosmeticos.net.br',
  },

  trustvox: {
    storeId: '393',
    perPage: '4',
    page: '1',
  },

  // Production URLs
  finalUrl: 'https://www.epocacosmeticos.com.br',
  storeUrl: 'https://loja.epocacosmeticos.net.br',
  secureSubdomain: 'https://secure.loja.epocacosmeticos.net.br',
  checkoutUrl: 'https://secure.loja.epocacosmeticos.net.br/checkout',
  loginUrl: 'https://secure.loja.epocacosmeticos.net.br/_secure/login',
  accountUrl:
    'https://secure.loja.epocacosmeticos.net.br/_secure/account#/profile',

  // Lighthouse CI
  lighthouse: {
    server: process.env.BASE_SITE_URL || 'http://localhost:3000',
    pages: {
      home: '/',
      pdp: '/my-way-giorgio-armani-perfume-feminino-edp/p',
      // pdpNova: '/la-vie-est-belle-eau-de-parfum-lancome-perfume-feminino/t',
      menuMarcas: '/marcas',
      collection: '/dermocosmeticos',
      // sacMarcas: '/centraldeatendimento/sac-marcas',
      // favoritos: '/_secure/favoritos',
      programaDeIntegridade: '/programa-de-integridade',
      brindes: '/selecao/ganhe-brindes',
      pesquisa: '/pesquisa?q=batom',
      search: '/s?q=batom',
      marcaModelo1: '/bvlgari',
    },
  },

  // E2E CI
  cypress: {
    pages: {
      home: '/',
      pdp: '/my-way-giorgio-armani-perfume-feminino-edp/p',
      collection: '/perfumes',
      collection_filtered:
        '/perfumes/perfume-masculino?category-1=perfumes&category-2=perfume-masculino&brand=piment&facets=category-1%2Ccategory-2%2Cbrand',
      search: '/s?q=dior',
    },
  },

  analytics: {
    // https://developers.google.com/tag-platform/tag-manager/web#standard_web_page_installation,
    gtmContainerId: 'GTM-NDQ3K6L',
  },

  storeLinks: {
    acessibilidade: '/institucional/acessibilidade',
  },
}
