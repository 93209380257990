import { useContext, useEffect, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import slugify from 'slugify'

import SimpleButton from 'src/components/ui/Button'
import { StoreContext } from 'src/providers/StoreProvider'

export interface ToggleSubMenuMobileProps {
  name: string
}

export const ToggleSubMenuMobile = ({ name }: ToggleSubMenuMobileProps) => {
  const { subMenuMobileToggle, submenuMobileOpened } = useContext(StoreContext)

  const [arrowUp, setArrowUp] = useState<boolean>(false)

  const menuActive = () => {
    subMenuMobileToggle(name)
    setArrowUp(!arrowUp)
  }

  useEffect(() => {
    setArrowUp(name === submenuMobileOpened)
  }, [name, submenuMobileOpened])

  return (
    <SimpleButton
      data-testid="toogleSubmenuMobile"
      data-reference={`submenu_${slugify(name.toLowerCase())}`}
      className="xl:hidden"
      onClick={menuActive}
      aria-label={`Abrir menu ${name}`}
    >
      <ChevronDownIcon
        className={`w-[24px] mr-1 transition-all duration-300 ${
          arrowUp ? 'rotate-180' : ''
        }`}
      />
    </SimpleButton>
  )
}
