import { useEffect, useState } from 'react'

import type { ProgressBarProps } from './ReguaDeBeneficios'

export const ProgressBar = ({ currentPromo, totalCart }: ProgressBarProps) => {
  const [textView, setTextView] = useState('')
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [animate, setAnimate] = useState(false)
  const [widthbar, setWidthbar] = useState(0)

  // essa função controla a animação do tamanho da regua e coloca e retira a classe 'animate' que faz um efeito de barra enchendo na div
  function animateTime(
    isAnimate: boolean,
    animatebefore: boolean,
    percent: number,
    step: number,
    timeout: number
  ) {
    if (animatebefore) {
      setAnimate(true)
    }

    setTimeout(() => {
      if (!animatebefore) {
        setAnimate(isAnimate)
      }

      setWidthbar(percent)

      setCurrentStep(step)
    }, timeout)
  }

  // prettier-ignore
  useEffect(() => { // NOSONAR
    const animateBar = (step: number, percent: number, fullGift = false) => {
      const timeoutMenor = 1000
      const timeoutMaior = 2500

      switch (true) {
        case step !== currentStep && currentStep === 0:
          // se é o primeiro nível
          animateTime(true, false, percent, step, timeoutMenor)
          break

        case step > currentStep:
          // quer dizer que mudou de promoção e passou de nivel, faz a animação
          setWidthbar(100)

          setTimeout(() => {
            setAnimate(false)
            setWidthbar(0)
          }, timeoutMenor)

          animateTime(true, false, percent, step, timeoutMaior)
          break

        case currentStep === 0:
          animateTime(true, true, percent, step, 300)
          break

        case !fullGift:
          // se a barra já passou do teto do valor da ultima promoção e já está com todos os brindes 'ganhos'
          animateTime(true, true, percent, step, 300)
          break

        default:
          setWidthbar(percent)
          break
      }

      return true
    }

    if (currentPromo) {
      const step = currentPromo?.step

      if (step === 1) {
        const subt = currentPromo.totalValueFloor - totalCart
        const diff = subt.toFixed(2).replace('.', ',')
        const msg =
          subt <= 0
            ? `Parabéns, você ganhou <strong>Frete Grátis</strong>`
            : `Faltam apenas <strong>${diff}</strong> para você ganhar Frete Grátis`

        setTextView(msg)

        const percent = (totalCart * 100) / currentPromo.totalValueFloor
        const setPercent = percent > 100 ? 100 : percent

        animateBar(step, setPercent)
      } else if (step > 1) {
        // se é o ultimo passo
        if (step === 4 && totalCart >= currentPromo.totalValueFloor) {
          const gift = `Frete Grátis e + ${step - 1} ${
            step > 2 ? 'brindes' : 'brinde'
          }`

          setTextView(`Parabéns, você ganhou <strong>${gift}</strong>`)

          animateBar(step, 100, true)
        } else {
          const diff = (currentPromo.totalValueFloor - totalCart)
            .toFixed(2)
            .replace('.', ',')

          const gift = `Frete Grátis e + ${step - 1} ${
            step > 2 ? 'brindes' : 'brinde'
          }`

          setTextView(
            `Faltam apenas <strong>${diff}</strong> para você ganhar ${gift}`
          )
          const percent = (totalCart * 100) / currentPromo.totalValueFloor

          animateBar(step, percent)
        }
      }
    }
  }, [totalCart, currentPromo, currentStep])

  useEffect(() => {
    setAnimate(true)

    setTimeout(() => {
      setAnimate(false)
    }, 4000)
  }, [widthbar])

  return (
    <section
      data-testid="progressBar"
      className="text-center relative overflow-hidden"
    >
      <div className="progress_bar__wrapper bg-gray-light-500 h-[25px]">
        <div
          data-testid="progressBarMensages"
          className="progress_bar__mensages h-full relative z-20 sm:text-base text-xs !leading-7"
          dangerouslySetInnerHTML={{ __html: textView }}
        />
        <div
          data-testid="progressBarInner"
          className={`absolute bg-[#ffcdce] h-full top-0 left-0 z-10 ${
            animate ? 'animate' : ''
          }`}
          style={{ width: `${widthbar}%` }}
        />
      </div>
    </section>
  )
}
