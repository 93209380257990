import { gql } from '@faststore/graphql-utils'
import { useCookies } from 'react-cookie'

import type { RichFindProductsQueryQuery } from '@generated/graphql'

import { useQuery } from '../sdk/graphql/useQuery'
import { useSession } from 'src/sdk/session'
import { HashRich } from 'src/utils/RichRelevance'

export const queryData = gql`
  query RichFindProductsQuery(
    $lang: String
    $placement: String
    $query: String
    $rcs: String
    $rows: [Int]
    $sessionId: String
    $start: [Int]
    $userId: String
  ) {
    RichFindProducts(
      lang: $lang
      placement: $placement
      query: $query
      rcs: $rcs
      rows: $rows
      sessionId: $sessionId
      start: $start
      userId: $userId
    ) {
      message
      status
      placements {
        searchTrackingUrl
        rcs
        numFound
        placement
        addtoCartParams
        links {
          sponsored
          banner
          directlink {
            id
            title
            url
          }
        }
        merchandisingRuleIds
        entities
        docs {
          clickUrl
          imageId
          numReviews
          rating
          description
          estoque
          score
          skuJson
          linkId
          salePriceCents
          name
          effectivePriceCents
          priceCents
          id
          brand
          categoryId
          categoryName
        }
      }
    }
  }
`

interface RichFindProductsProps {
  query: string
}

/**
 * Hook de find products da rich
 */

export const useRichFindProducts = ({ query }: RichFindProductsProps) => {
  const [cookies] = useCookies(['VtexRCMacIdv7'])
  const { person } = useSession()
  const userId = person?.email ? HashRich(person?.email) : ''

  const infoVariables = {
    lang: 'pt',
    placement: 'search_page.find',
    query,
    rcs: 'eF5dksuyqkYARSvWySSjU_mG3GHfohvolqGKiCgI8tSJhc1LEGx5y6dleL4sJrfu4GaP9myv2rX-_KPs21v0CQlHAARw1vx1RYRIUJzz338UifvoqJqEPMIkFIDAzQkQQpwATEgIIIc5DJMIXWPpg_Vfv0e4SQn4GUFfFRqF2kYRm7VIHkeJeTR1ItWl8Z4GwdErxBMKRD_y0yf1_aA731VQrTYKqU8w5FXlDGHpV4g_rG0oTEna42mcqugil7R-XNLi0IL7beG9VD-9s3Qaq-Tw8sPskubB0aB3oJCGUOXVbrpiQveilnCZGboBVyUakJTzoazuhYq2RMN8L3CrQHV4Vd2N4z4L0telTyQtqGoAqqHQmJisn4t2y6Mhn3Z-Iqf4bKZm1Xfn8-W-jRfLeb5eWvlrsw3N3jcbxoKg5aQJjjLqcaV7Zc6U4mXmsaWDWy-EWSTspFOkjSs08sLDYXtjzA4Hq1pW6m6wy36hI3EpUFvCLnjA96PfPllcRaym2d8z-svXWF-slRgpHBAfUn7mlqshHVo3VLy9TSXF9e3NcSHI56N8TbV6wlFpG_IByX6g-qbZ5npkJ3Xj5FZXO3oo71ltPSXr8kyHtamJZUC3pVAfa8OCgOsMDDVSGfN56jaKhrR9E-UPoPbmfE3_Q_r2-TYriq9d-v2HXBgTQSKQg0ji36oB-EH7r9n_dMnv9Lm0TgNlkmKGGybLbij4N2hc7vnGE6m64_aObRhKZZWiobYnryLmvMcAoRPzDs_YekvjOkMt22CIJ08na-9wyevbwliPzYlxU1fIcHKOJ-X2CqzixOKp8Z7C9CKjl6Gtc6BcIYLlsb14SP8XaVbO3qTh129RmEtu5hWE-RSAdOtmnbkEBdqLMbSuHs9nxOnYWCzDVdl2Db8CK8OMF4ELFPXh94leg9KoF9fEUx6A-TGMBw-CRMW3zD2i99I_vqwdyg',
    rows: 4,
    sessionId: cookies.VtexRCMacIdv7 ? cookies.VtexRCMacIdv7 : '',
    start: 0,
    userId,
  }

  const options = { revalidateOnMount: true }

  const { data } = useQuery<RichFindProductsQueryQuery, any>(
    queryData,
    infoVariables,
    options
  )

  return data
}
