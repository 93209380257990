import { useState, useEffect } from 'react'
import axios from 'axios'

import { useCart } from 'src/sdk/cart'

import type { PromotionsItem, FreteGratisItem } from './ReguaDeBeneficios'
import { FreteGratisBar } from './FreteGratisBar'
import { ProgressBar } from './ProgressBar'

export interface FretegratisRequest {
  promotions: PromotionsItem[] | []
  fretegratis: FreteGratisItem[] | []
}

export const ReguaDeBeneficios = () => {
  const [promotions, setPromotions] = useState<PromotionsItem[]>([])
  const [currentPromo, setCurrentPromo] = useState<PromotionsItem>()

  const [fretegratis, setFreteGratis] = useState<FreteGratisItem[]>([])
  const [cartVal, setCartVal] = useState(0)

  const cart = useCart()

  useEffect(() => {
    axios
      .get<FretegratisRequest>(
        'https://utils.epocacosmeticos.net.br/api/regua-beneficios'
      )
      .then(({ data }) => {
        setPromotions(data.promotions)
        setFreteGratis(data.fretegratis)
      })
  }, [])

  useEffect(() => {
    const promosActiveds: PromotionsItem[] = promotions.filter(
      (item) => item.totalValueFloor >= cart.total
    )

    const _currentPromo: PromotionsItem = promosActiveds.length
      ? promosActiveds[0]
      : promotions[promotions.length - 1]

    setCurrentPromo(_currentPromo)
    setCartVal(cart.total)
  }, [cart.total, promotions])

  return (
    <div className="h-[25px]" data-cartval={cart.total} data-testid="rootRegua">
      {cart.total > 0 ? (
        <ProgressBar currentPromo={currentPromo} totalCart={cartVal} />
      ) : (
        <FreteGratisBar infopromos={fretegratis} delay={5} />
      )}
    </div>
  )
}
