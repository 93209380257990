import { reclameAqui } from './FooterReclameAqui'

export const FooterSelos = () => {
  // Selos que precisam ainda ser carregados com o domínio do site
  return (
    <div
      data-testid="stamps"
      className="container mb-2 pt-[8px] pr-[15px] pb-[8px] pl-[15px]"
    >
      <ul className="lg:pt-[20px] lg:pl-[90px] lg:pr-[90px] lg:pb-[20px] flex justify-center flex-wrap">
        <li className="selo-ebit mr-4">
          <a
            href="http://www.ebit.com.br/5354"
            target="_blank"
            data-noop="redir(this.href);"
            id="seloEbit"
            rel="noreferrer"
          >
            <span style={{ display: 'none' }}>Ebit</span>
          </a>
        </li>
        <li className="selo-site-blindado mr-4 pt-4">
          <div id="armored_website">
            <param id="aw_preload" value="true" />
            <param id="aw_use_cdn" value="true" />
          </div>
        </li>
        <li className="selo-compre-confie mr-4 pt-2">
          <a
            href="http://www.compreconfie.com.br/100935"
            target="_blank"
            rel="noreferrer"
            id="seloEconfy"
            data-noop="redir(this.href);"
          >
            <span style={{ display: 'none' }}>Compre e confie</span>
          </a>
        </li>
        <li className="mr-4 pt-[18px]">
          <a
            href="https://www.reclameaqui.com.br/como-funciona/selo/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://epocacosmeticos.vteximg.com.br/arquivos/ra1000-epocacosmeticos.jpg?v=635521656666170000"
              alt="Certificado reclame aqui"
              width={102}
              height={53}
              className="h-auto"
              loading="lazy"
            />
          </a>
        </li>
        <li className="mr-4 pt-1">
          <img
            src={reclameAqui}
            alt="Reclame aqui"
            width={130}
            height={64}
            className="h-auto"
            loading="lazy"
          />
        </li>
      </ul>
    </div>
  )
}
