import Image from 'next/image'

import { formatPrice } from 'src/utils'

import type { ShelfMenuProductsProps } from './ShelfMenu'

export const ShelfMenuItem = ({
  brand,
  name,
  price,
  image,
  sku,
}: ShelfMenuProductsProps) => {
  return (
    <div className="item_shelf_menu flex items-center border-b border-primary-200 last:border-0 py-1">
      <div className="item_shelf_image flex-initial mr-1">
        <Image src={image} alt={name} width={80} height={80} loading="lazy" />
      </div>
      <div className="item_shelf_content">
        <h4 className="text-sm font-semibold mt-[-5px] mb-[4px]">{brand}</h4>
        <h5 className="text-xs whitespace-nowrap text-ellipsis overflow-hidden w-[220px] mb-[4px]">{`${name} - ${sku}`}</h5>
        <p className="font-semibold text-[#545454] text-sm">
          {formatPrice(price)}
        </p>
      </div>
    </div>
  )
}
