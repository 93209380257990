import type { KeyboardEvent, MouseEvent, FocusEvent } from 'react'
import { useContext, useState } from 'react'
import slugify from 'slugify'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'

import { StoreContext } from 'src/providers/StoreProvider'
import SimpleButton from 'src/components/ui/Button'

import megaMenu from '../../../../public/cmsHeader.json'
import type { SectionMenuAllItems } from './SectionMenu'
import { NavbarItem } from './NavbarItem'

export const Megamenu = () => {
  const classClosed =
    'fixed inset-y-0 -left-[100%] w-full bg-white z-50 xl:z-30'

  const classOpen =
    'visible fixed inset-y-0 left-0 w-full bg-white z-50 xl:z-30'

  const [overlayOpen, setOverlayOpen] = useState<boolean>(false)
  const [submenuOpened, setSubmenuOpened] = useState<string>('')
  const [timeFunc, setTimeFunc] = useState<ReturnType<typeof setTimeout>>()

  const { menuOpen, toggleMenu, accessibleMenuFocusChange } =
    useContext(StoreContext)

  /* Consulta GraphQl para buscar a informação do CMS, com os itens e prateleiras do menu.
   * Essa consulta busca pelo id, o tipo de conteudo CMS Mega Menu e traz as sections.
   */
  const menu = megaMenu
  const { data } = menu ?? {}
  const { sections } = data[0] ?? []
  const { data: sectionData } = sections[0] ?? []

  // Salvando itens do menu
  const menuItens = sectionData.allItems

  // Função para acessibilidade, ao ter o foco no botao de menu mobile, ao apertar os botoes especificos o menu abre.
  const onKeydownHandler = (e: KeyboardEvent<HTMLButtonElement>) => {
    if (
      e.code === 'ArrowLeft' ||
      e.code === 'Space' ||
      e.code === 'NumpadEnter' ||
      e.code === 'Enter'
    ) {
      toggleMenu()
    }

    return false
  }

  const setSubmenuOpenedHandler = (name?: string) => {
    if (name) {
      setSubmenuOpened(name)
    }

    return false
  }

  // funcção que controla o fechamento do menu do overlay do menu
  const overlayHandler = (
    event: MouseEvent | FocusEvent,
    item?: SectionMenuAllItems
  ) => {
    event.stopPropagation()
    const triggerInfo = !!(item && item.submenucoluna1)

    if (timeFunc) {
      clearTimeout(timeFunc) // para não empilhar timeouts e fazer uma fila de eventos seguidos
    }

    // Delay para abrir o menu, ao fazer o hover, tem um dalay para não abrir imediatamente o menu e evitar abrir o menu sem o
    // usuário realmente querer abrir.
    setTimeFunc(
      setTimeout(() => {
        setOverlayOpen(triggerInfo)
        setSubmenuOpenedHandler(item?.nome)
      }, 100)
    )
  }

  const closeOverlay = (event?: MouseEvent | FocusEvent) => {
    // limpando o timeout disparado para evitar de setar o overlay como false antes dele ter virado true, devido ao delay
    if (timeFunc) {
      clearTimeout(timeFunc) // para não empilhar timeouts e fazer uma fila de eventos seguidos
    }

    setTimeFunc(
      setTimeout(() => {
        setOverlayOpen(false)
        setSubmenuOpened('')
      }, 100)
    )

    if (event?.type !== 'blur') {
      accessibleMenuFocusChange('')
    }
  }

  // função que ativa o menu no focus, para navegação do menu via teclado, usando o TAB
  const acessibleHandleMenu = (
    event: FocusEvent,
    item?: SectionMenuAllItems
  ) => {
    event.stopPropagation()

    if (item) {
      accessibleMenuFocusChange(item.nome)
    }

    const triggerInfo = !!(item && item.submenucoluna1)

    setOverlayOpen(triggerInfo)
  }

  return (
    <>
      <nav className="bg-white">
        <ul
          data-testid="megaMenuList"
          id="megamenuList"
          aria-labelledby="menubutton"
          role="menubar"
          className={`transition-all overflow-y-auto xl:border-t xl:border-primary-200 min-h-screen
                  ${menuOpen ? classOpen : classClosed}
                  xl:relative xl:inset-y-auto xl:inset-x-auto xl:flex container xl:mx-auto xl:min-h-fit
                  `}
        >
          {/* botao de fechar no mobile */}
          <li
            className="wrapper_close_menu_mobile xl:hidden bg-pink text-white"
            role="menuitem"
          >
            <SimpleButton
              data-testid="closeMenuMobile"
              aria-label="Fechar menu"
              className="close_menu_mobile p-3 inline-flex"
              type="button"
              onClick={toggleMenu}
              onKeyDown={onKeydownHandler}
            >
              <ChevronLeftIcon className="w-4" />
              <span>Fechar</span>
            </SimpleButton>
          </li>

          {menuItens.map((item, index) => {
            // Itens do menu
            return (
              <NavbarItem
                item={item}
                onFocusLi={overlayHandler}
                onMouseLeaveLi={closeOverlay}
                onBlurLink={closeOverlay}
                onFocusLink={acessibleHandleMenu}
                onMouseOverLi={overlayHandler}
                subMenuOpened={submenuOpened}
                key={`${slugify(item.nome)}-${index}`}
              />
            )
          })}
        </ul>

        <div
          className={`overlay_menu xl:bg-black xl:fixed xl:z-20 xl:inset-x-0 min-h-screen opacity-30 absolute
                      ${overlayOpen ? 'visible' : 'invisible'}
                    `}
          onFocus={(e) => overlayHandler(e)}
          onMouseOver={(e) => overlayHandler(e)}
        />
      </nav>
    </>
  )
}
