import { useEffect, useState } from 'react'
import { Link as ReactLink } from 'react-scroll'

const SCROLL_OFFSET = -230

export const SubmenuMarcas = () => {
  const [list, setList] = useState<string[]>([])

  const urlHash = () => {
    if (window) {
      return window.location.pathname
    }

    return ''
  }

  useEffect(() => {
    const alpha = Array.from(Array(26)).map((_, i) => i + 65)
    const alphabet = alpha.map((x) => String.fromCharCode(x))

    alphabet.push('0-9')
    alphabet.push('Todas')
    setList(alphabet)
  }, [])

  return (
    <ul
      className="container mx-auto flex-[4_1_0%] ml-4 xl:py-2 xl:ml-0 xl:mr-2
      grid gap-3 xl:grid-cols-6 xl:grid-rows-5 grid-flow-col grid-cols-4 grid-rows-7"
    >
      {list.map((letra) => {
        let differentLetter = ''

        if (letra === '0-9' || letra.toLowerCase() === 'todas') {
          differentLetter = '123'
        }

        return (
          <li key={`letra_${letra}`}>
            {urlHash() === '/marcas' ? (
              // Caso já esteja dentro da página de marcas e clique em uma das letras no submenu
              <ReactLink
                title={letra}
                smooth
                offset={SCROLL_OFFSET}
                to={differentLetter || letra}
                key={letra}
                className="cursor-pointer"
              >
                {letra}
              </ReactLink>
            ) : (
              <ReactLink
                title={letra}
                key={letra}
                to={`/marcas#${differentLetter || letra}`}
              >
                {letra}
              </ReactLink>
            )}
          </li>
        )
      })}
    </ul>
  )
}
