import type { FormEvent, KeyboardEvent } from 'react'
import { useState } from 'react'
import slugify from 'slugify'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import SimpleButton from 'src/components/ui/Button'
import { useRichAutoComplete } from 'src/hooks/useRichAutoComplete'
import { AutocompleteListItem } from './AutocompleteListItem'
import { AutocompleteListProductsItem } from './AutocompleteListProductsItem'
import { useRichFindProducts } from 'src/hooks/useRichFindProducts'
import Link from 'next/link'

export const SearchInputRich = () => {
  const [inputVal, setInputVal] = useState('')
  const [sugestOpen, setSugestOpen] = useState<boolean>(false) // abre ou fecha o bloco do autosugest

  const sugests = useRichAutoComplete({ query: inputVal })

  const sugestProducts = useRichFindProducts({ query: inputVal })

  const products = {
    rcs: sugestProducts?.RichFindProducts?.placements?.[0]?.rcs,
    docs: sugestProducts?.RichFindProducts?.placements?.[0]?.docs,
    category:
      sugestProducts?.RichFindProducts?.placements?.[0]?.docs?.[0]
        ?.categoryName?.[0],
    departament:
      sugestProducts?.RichFindProducts?.placements?.[0]?.docs?.[0]
        ?.categoryName?.[1],
    directlink:
      sugestProducts?.RichFindProducts?.placements?.[0]?.links?.directlink,
  }

  const redirectLink = products?.directlink

  const slicedProducts = products?.docs?.slice(0, 3)

  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    // NOSONAR
    const term = event.target.value // valor digitado no input
    const minTermToSearch = 3 // só faz o autocomplete após ter pelo menos 3 itens

    setInputVal(term) // controla o valor do input

    if (term && term.length >= minTermToSearch) {
      setSugestOpen(true)
    }

    return false
  }

  // função que fecha o bloco do auto complete e zera o valor do input quando sair dele
  const closeSugest = () => {
    setInputVal('')
    setSugestOpen(false)
  }

  // função para acessibilidade
  const closeSugestKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Space' || e.code === 'NumpadEnter' || e.code === 'Enter') {
      setInputVal('')
      setSugestOpen(false)
    }

    return false
  }

  // função que faz o usuário ir para pagina de busca caso não clique em nenhuma sugestão
  const formSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault()

    if (inputVal) {
      const redirect = redirectLink?.[0]?.id ?? `/pesquisa?q=${inputVal}`

      window.location.href = redirect
    }

    return false
  }

  const classesH4 = 'font-semibold text-pb-700 mb-1'
  const classesOpen = 'visible max-h-fit z-50'
  const classesClose = 'invisible max-h-0 z-45'

  return (
    // submit do form levando para pagina de pesquisa com o termo digitado
    <form
      className="
            flex-1 relative
            border-primary-200
            w-full min-w-full order-last mt-2
            xl:w-auto xl:min-w-[500px] xl:order-none xl:max-w-min
          "
      onSubmit={formSubmit}
      data-testid="formSearchRich"
    >
      <input
        data-testid="inputSearchRich"
        type="text"
        className={`bg-gray-light px-2 py-1 rounded w-full relative ${
          sugestOpen ? 'z-47' : 'z-45'
        }`}
        placeholder="Oi, o que você procura hoje? :)"
        onChange={inputHandler}
        tabIndex={0}
        value={inputVal}
      />
      <SimpleButton
        type="submit"
        className={`right-0 top-0 absolute h-40 w-40 flex items-center justify-center ${
          sugestOpen ? 'z-47' : 'z-45'
        }`}
        tabIndex={0}
        aria-label="Pesquisar"
      >
        <MagnifyingGlassIcon className="w-[24px] text-primary-500" />
      </SimpleButton>

      <section
        className={`autocomplete_wrapper mt-0.5
                  absolute flex justify-center w-full xl:mt-[4px]
                  ${sugestOpen ? classesOpen : classesClose}
                  `}
      >
        <div
          className={`autocomplete_content overflow-hidden
                  bg-gray-light shadow p-1 flex flex-col rounded-sm
                    fixed left-0 xl:left-auto min-w-full
                    xl:relative xl:min-w-[700px] xl:flex-row
                    ${sugestOpen ? 'max-h-fit' : 'max-h-0'}
                  `}
        >
          <div className="leftcol w-full xl:w-1/2 p-1">
            <div
              className="autocomplete_text border-b border-b-primary-300
                        xl:min-h-[150px] xl:mb-2 xl:pb-1"
            >
              <h4 className={classesH4}>Os mais buscados aqui</h4>
              <ul data-testid="autocompleteList">
                {sugests?.RichAutoComplete?.map((sugest) => {
                  return (
                    <AutocompleteListItem
                      key={sugest?.id}
                      term={sugest?.terms}
                      handleClick={closeSugest}
                    />
                  )
                })}
              </ul>
            </div>

            <div className="categories_list hidden xl:block xl:min-h-[150px]">
              <h4 className={classesH4}>Categorias</h4>
              <ul>
                <li className="w-full block hover:text-pink hover:bg-rose-50 px-1 py-0.5">
                  <Link
                    href={slugify(`/${products.category}`, { lower: true })}
                  >
                    {products.category ?? ''}
                  </Link>
                  {products.departament !== undefined ? ' > ' : ''}
                  <Link
                    href={slugify(
                      `/${products.category}/${products.departament}`,
                      { lower: true, remove: /&/g }
                    )}
                  >
                    {products.departament ?? ''}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="rightcol w-full xl:w-1/2 p-1">
            <h4 className={classesH4}>Produtos</h4>
            <ul
              className="overflow-y-auto max-h-[280px] xl:max-h-full"
              data-testid="autocompleteListProducts"
            >
              {slicedProducts?.map((product, i) => {
                return (
                  <AutocompleteListProductsItem
                    closeFunc={closeSugest}
                    {...product}
                    last={i === i - 1}
                    key={product?.id}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </section>

      <div
        data-testid="overlayBusca"
        onClick={closeSugest}
        className={`
                  overlay_busca fixed inset-0 min-h-screen bg-black z-46 transition-all
                  ${sugestOpen ? 'opacity-30 visible' : 'opacity-0 invisible'}
              `}
        onKeyDown={closeSugestKeyPress}
        role="none"
      />
    </form>
  )
}
