import type { Dispatch, SetStateAction } from 'react'
import { XCircleIcon } from '@heroicons/react/24/outline'

import { SimpleButton } from 'src/components/ui/Button/SimpleButton'

export interface ModalBoxProps {
  opened: boolean
  toggle: Dispatch<SetStateAction<boolean>>
  content?: string
  className?: string
  testId?: string
  children?: JSX.Element | string
}

export const ModalBox = ({
  opened,
  toggle,
  content,
  className,
  testId,
  children,
}: ModalBoxProps) => {
  const handlerClick = () => {
    toggle(!opened)
  }

  return (
    <>
      <div
        className={`modalbox_component ${className}
        fixed lg:top-1/3 left-0 px-1 top-[20%] w-full z-50 transition-all
        ${
          opened
            ? 'visible opacity-100 top'
            : 'invisible opacity-0 h-0 overflow-hidden'
        }
      `}
        data-testid={testId}
      >
        <div
          className="bg-warmGray-50 lg:max-w-fit rounded py-3 px-2 drop-shadow-lg 
                    relative mx-auto"
        >
          <SimpleButton
            onClick={handlerClick}
            className="absolute lg:-right-0.5 -right-0.5 lg:-top-0.5 -top-0.5 bg-warmGray-50 rounded-full hover:text-pink transition-all bg-white"
          >
            <XCircleIcon className="w-5" />
          </SimpleButton>

          {content && (
            <div
              className="p-3 max-h-[400px] overflow-auto bg-white rounded"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}

          {children}
        </div>
      </div>
      <div
        className={`${
          opened
            ? 'visible opacity-30'
            : 'invisible opacity-0 h-0 overflow-hidden'
        }
                  modalbox_component_overlay fixed inset-0 bg-black z-48 cursor-pointer transition-all`}
        onClick={handlerClick}
        onKeyDown={() => null}
        role="none"
      />
    </>
  )
}
