import { gql } from '@faststore/graphql-utils'
import { useCookies } from 'react-cookie'

import type { RichAutoCompleteQueryQuery } from '@generated/graphql'

import { useQuery } from '../sdk/graphql/useQuery'
import { useSession } from 'src/sdk/session'
import { HashRich } from 'src/utils/RichRelevance'

export const queryData = gql`
  query RichAutoCompleteQuery(
    $lang: String
    $query: String
    $rows: [Int]
    $sessionId: String
    $start: [Int]
    $userId: String
  ) {
    RichAutoComplete(
      lang: $lang
      query: $query
      rows: $rows
      sessionId: $sessionId
      start: $start
      userId: $userId
    ) {
      id
      terms
      type
      value
    }
  }
`

interface RichAutoCompleteProps {
  query: string
}

/**
 * Hook de autocomplete da rich
 */

export const useRichAutoComplete = ({ query }: RichAutoCompleteProps) => {
  const { person } = useSession()
  const [cookies] = useCookies(['VtexRCMacIdv7'])
  const sessionId = cookies.VtexRCMacIdv7
  const userId = person?.email ? HashRich(person?.email) : '' // passando o userId com o hash do e-mail

  const lang = 'pt'
  const rows = 4
  const start = 0

  const infoVariables = {
    lang,
    query,
    rows,
    sessionId,
    start,
    userId,
  }

  const options = { revalidateOnMount: true }

  const { data } = useQuery<RichAutoCompleteQueryQuery, RichAutoCompleteProps>(
    queryData,
    infoVariables,
    options
  )

  return data
}
