import type { SectionSubmenu } from '../Megamenu/SectionMenu'
import { List as UIList } from '@faststore/ui'
import { StoreContext } from 'src/providers/StoreProvider'
import type { AnchorHTMLAttributes } from 'react'
import { useContext } from 'react'
import slugify from 'slugify'

export interface SubmenuMobileProps {
  name: string
  submenucoluna1: SectionSubmenu[]
  submenucoluna2: SectionSubmenu[]
  submenucoluna3: SectionSubmenu[]
  onClickLink?: AnchorHTMLAttributes<HTMLAnchorElement>['onClick']
}

export const SubmenuMobile = ({
  onClickLink,
  name,
  submenucoluna1,
  submenucoluna2,
  submenucoluna3,
}: SubmenuMobileProps) => {
  const links = [...submenucoluna1, ...submenucoluna2, ...submenucoluna3]

  const { submenuMobileOpened } = useContext(StoreContext)

  return (
    <>
      {submenuMobileOpened === name && (
        <UIList
          data-fs-submenu-mobile
          data-testid="submenuMobile"
          data-reference={`submenu_${slugify(name.toLowerCase())}`}
        >
          {links.map(({ url, nome }) => (
            <li key={nome} data-fs-navlinks-list-item>
              <a href={url} data-fs-navlinks-link onClick={onClickLink}>
                {nome}
              </a>
            </li>
          ))}
        </UIList>
      )}
    </>
  )
}
