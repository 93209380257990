import { Bars3Icon } from '@heroicons/react/24/outline'
import slugify from 'slugify'

import { Submenu } from './Submenu'
import { ToggleSubMenuMobile } from './ToggleSubMenuMobile'
import type { SectionMenuAllItems } from './SectionMenu'

export interface NavbarItemProps {
  item: any
  onMouseOverLi: (
    event: React.MouseEvent<HTMLLIElement>,
    item?: SectionMenuAllItems
  ) => void
  onFocusLi: (
    event: React.FocusEvent<HTMLLIElement>,
    item?: SectionMenuAllItems
  ) => void
  onMouseLeaveLi: () => void
  onFocusLink: (
    event: React.FocusEvent<HTMLAnchorElement>,
    item?: SectionMenuAllItems
  ) => void
  onBlurLink: () => void
  subMenuOpened: string
}

export const NavbarItem = ({
  item,
  onMouseOverLi,
  onFocusLi,
  onMouseLeaveLi,
  onFocusLink,
  onBlurLink,
  subMenuOpened,
}: NavbarItemProps) => {
  const ariaFlow =
    item?.nome && item?.submenucoluna1
      ? `submenu_${slugify(item.nome.toLowerCase())}`
      : ''

  const currentOpened = subMenuOpened === item?.nome

  return (
    <li
      data-testid="navbarItem"
      className="xl:flex-auto basis-full justify-center group"
      role="none"
      onMouseOver={(event) => onMouseOverLi(event, item)}
      onFocus={(event) => onFocusLi(event, item)}
      onMouseLeave={onMouseLeaveLi}
    >
      <span
        className="flex w-full justify-between items-center xl:flex-auto"
        role="none"
      >
        <a
          role="menuitem"
          href={item?.url ? item.url : '#'}
          className={`p-2 relative transition-all w-full
                    xl:after:block xl:after:absolute xl:after:bottom-[5px] xl:after:left-0 xl:after:h-[2px] xl:after:w-0
                    after:transition-all xl:after:bg-pink
                    xl:flex xl:justify-center
                    ${
                      currentOpened
                        ? 'hover:text-pink xl:hover:after:w-full xl:group-focus:after:w-full xl:group-focus-visible:after:w-full'
                        : ''
                    }
                    `}
          tabIndex={0}
          onFocus={(event) => onFocusLink(event, item)}
          onBlur={onBlurLink}
          aria-flowto={ariaFlow} // flowto direciona qual submenu está atrelado a esse e linka para que na navegação pelo teclado o fluxo seja correto.
        >
          {item?.nome.toLowerCase() === 'ver tudo' ? (
            <Bars3Icon className="max-w-[20px] hidden xl:inline-block xl:flex-shrink-0 xl:mr-[3px]" />
          ) : (
            ''
          )}
          <span className="xl:flex-shrink-0">{item?.nome}</span>
        </a>
        {item?.submenucoluna1 && <ToggleSubMenuMobile name={item.nome} />}
      </span>
      {item?.submenucoluna1 && (
        <Submenu
          name={item.nome}
          items={{
            coluna1: item.submenucoluna1,
            coluna2: item?.submenucoluna2,
            coluna3: item?.submenucoluna3,
          }}
          prateleira={item.prateleira}
          currentOpened={currentOpened}
        />
      )}
    </li>
  )
}
