import { List as UIList } from '@faststore/ui'
import type { AnchorHTMLAttributes } from 'react'
import { ToggleSubMenuMobile } from './ToggleSubMenuMobile'
import { mark } from 'src/sdk/tests/mark'

import menuLinks from '../../../../public/cmsHeader.json'
import styles from './navlinks.module.scss'
import { SubmenuMobile } from './SubmenuMobile'

interface NavLinksProps {
  onClickLink?: AnchorHTMLAttributes<HTMLAnchorElement>['onClick']
  classes?: string
}

const menu = menuLinks.data
const { sections } = menu[0]
const { data } = sections[0]
const links = data.allItems.map((item) => {
  return {
    name: item.nome,
    href: item.url,
    ...item,
  }
})

links.splice(0, 1)

function NavLinks({ onClickLink, classes = '' }: NavLinksProps) {
  return (
    <nav className={`${styles.fsNavlinks} ${classes}`}>
      <div>
        <UIList data-fs-navlinks-list>
          {links.map(
            ({
              href,
              name,
              submenucoluna1,
              submenucoluna2,
              submenucoluna3,
            }) => (
              <li key={name} data-fs-navlinks-list-item>
                <a data-fs-navlinks-link href={href} onClick={onClickLink}>
                  {name}
                </a>
                {submenucoluna1 && <ToggleSubMenuMobile name={name} />}
                {submenucoluna1 && (
                  <SubmenuMobile
                    onClickLink={onClickLink}
                    name={name}
                    submenucoluna1={submenucoluna1 ?? []}
                    submenucoluna2={submenucoluna2 ?? []}
                    submenucoluna3={submenucoluna3 ?? []}
                  />
                )}
              </li>
            )
          )}
        </UIList>
      </div>
    </nav>
  )
}

export default mark(NavLinks)
