import slugify from 'slugify'
import storeConfig from 'store.config'

export interface MenuDesLogadoProps {
  handlerBlur: (last: boolean) => boolean
}

const secureSubdomain = storeConfig.secureSubdomain

export const MenuDesLogado = ({ handlerBlur }: MenuDesLogadoProps) => {
  const itemsAccountDesLogado = [
    {
      texto: 'Entrar',
      url: '/login',
    },
    {
      texto: 'Minha Conta',
      url: `${secureSubdomain}/_secure/account#/profile`,
    },
    {
      texto: 'Meus Pedidos',
      url: `${secureSubdomain}/_secure/account/#/orders`,
    },
    {
      texto: 'Rastrear meus Pedidos',
      url: '/centraldeatendimento/rastrear-pedido',
    },
    {
      texto: 'Atendimento',
      url: 'https://atende.epocacosmeticos.com.br/hc/pt-br',
    },
  ]

  return (
    <>
      {itemsAccountDesLogado.map((item, index) => {
        const keyItem = slugify(item.texto.toLocaleLowerCase())

        if (item.texto === 'Entrar') {
          return (
            <li className={`py-0.5 ${keyItem}`} key={keyItem}>
              <a
                role="menuitem"
                tabIndex={0}
                href={item.url}
                className={`${keyItem} block transition-all bg-black text-white rounded p-0.5 font-semibold hover:bg-gray-dark focus-within:bg-gray-dark`}
                onBlur={() =>
                  handlerBlur(index === itemsAccountDesLogado.length - 1)
                }
              >
                {item.texto}
              </a>
            </li>
          )
        }

        return (
          <li className={`py-0.5 ${keyItem}`} key={keyItem}>
            <a
              role="menuitem"
              tabIndex={0}
              href={item.url}
              className={`${keyItem} block transition-all  text-left px-0.5 hover:text-pink focus-within:text-pink`}
              onBlur={() =>
                handlerBlur(index === itemsAccountDesLogado.length - 1)
              }
            >
              {item.texto}
            </a>
          </li>
        )
      })}
    </>
  )
}
