import { useEffect, useState } from 'react'

import { useProductsQuery } from 'src/sdk/product/useProductsQuery'

import { ShelfMenuItem } from './ShelfMenuItem'

export interface ShelfMenuProps {
  content: {
    nome?: string
    skus?: string
  }
}

export interface ShelfMenuProductsProps {
  brand: string
  name: string
  price: number
  image: string
  slug: string
  skuId: string
  sku: string
}

export const ShelfMenu = ({ content: { nome, skus } }: ShelfMenuProps) => {
  const skuList = skus?.split(',')
  const productsArr: ShelfMenuProductsProps[] = []
  const [products, setProducts] = useState(productsArr)

  const productList = useProductsQuery({
    first: 10,
    after: '',
    sort: 'score_desc',
    selectedFacets: [],
    term: `sku:${skuList?.join(';')}`,
  })

  useEffect(() => {
    productList?.edges.forEach((edge) => {
      const item = edge.node
      let imageResize = item.image[0].url

      try {
        const [domainImage, imageIdPath] = item.image[0].url.split('ids/')
        const [imageId, imagePath] = imageIdPath.split('/')

        imageResize = `${domainImage}ids/${imageId}-80-80/${imagePath}`
      } catch (error) {
        console.warn(error)
      }

      productsArr.push({
        brand: item.brand.name,
        name: item.isVariantOf.name,
        price:
          item.offers.lowPrice === 0
            ? item.offers.offers[0].price
            : item.offers.lowPrice,
        image: imageResize,
        slug: item.slug,
        skuId: item.id,
        sku: item.name,
      })
    })

    setProducts(productsArr)
  }, [productList])

  if (!skus) {
    return <></>
  }

  return (
    <div className="prateleira flex-initial hidden xl:flex xl:flex-col xl:w-[300px] xl:py-2">
      <h4 className="font-semibold border-b border-b-primary-200 mb-1">
        {nome}
      </h4>
      <div>
        {products.map((product, i) => {
          const slugWithoutSku = product.slug.replace(`-${product.skuId}`, '')

          return (
            <a href={`/${slugWithoutSku}/p`} className="cursor-pointer" key={i}>
              <ShelfMenuItem {...product} />
            </a>
          )
        })}
      </div>
    </div>
  )
}
