import { List as UIList } from '@faststore/ui'
import type { AnchorHTMLAttributes } from 'react'
import { mark } from 'src/sdk/tests/mark'

import styles from './navlinks.module.scss'

interface NavLinksProps {
  onClickLink?: AnchorHTMLAttributes<HTMLAnchorElement>['onClick']
  classes?: string
}

const footerLinks = [
  {
    name: 'Minha conta',
    href: '#',
  },
  {
    name: 'Meus Pedidos',
    href: '#',
  },
  {
    name: 'Rastrear seu Pedido',
    href: '#',
  },
  {
    name: 'Atendimento',
    href: '#',
  },
]

function NavLinks({ onClickLink, classes = '' }: NavLinksProps) {
  return (
    <nav className={`${styles.fsNavlinks} ${classes}`}>
      <div>
        <UIList data-fs-navlinks-list>
          {footerLinks.map(({ href, name }) => (
            <li key={name} data-fs-navlinks-list-item>
              <a data-fs-navlinks-link href={href} onClick={onClickLink}>
                {name}
              </a>
            </li>
          ))}
        </UIList>
      </div>
    </nav>
  )
}

export default mark(NavLinks)
