import { useCookies } from 'react-cookie'
import { parse } from 'qs'

/**
 * Function para consultar usuário logado (hash) e cookie de sessão, e retornar junto com esses dados  as  informações de apiKey e apiClientKey
 * @returns {Array} - apiKey, apiClientKey, sessionId e userId
 */

export const HashRich = (paramEmail: string) => {
  const origem = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'x',
    'z',
    'w',
    'k',
    'y',
    ',',
  ]

  const destino = [
    'c',
    'F',
    'g',
    'h',
    'J',
    't',
    'W',
    'a',
    'b',
    's',
    'C',
    'x',
    'm',
    'k',
    'I',
    'O',
    'l',
    'Q',
    'B',
    'e',
    'r',
    'R',
    'T',
    'y',
    'u',
    'U',
    'A',
    'n',
  ]

  const emailArray = paramEmail.split('')
  let emailHash = ''

  //
  // eslint-disable-next-line func-names
  emailArray.forEach(function (e) {
    for (let i = 0; i < origem.length; i++) {
      if (e === origem[i]) {
        emailHash += destino[i]
      }
    }
  })

  return emailHash
}

export const User = (email: string | null | undefined) => {
  const [vtexCookie] = useCookies(['VtexRCMacIdv7'])
  const apiKey = 'c85912f892c73e30'
  const apiClientKey = 'd87e7a0748a78f10'

  return {
    apiKey,
    apiClientKey,
    sessionId: vtexCookie.VtexRCMacIdv7,
    userId: email ? HashRich(email) : '',
    channelId: 'cb05f4cf5c9ecd3d',
  }
}

/**
 * Função que decodifica o preço para colocar amigavelmente na url
 */
export const decodePriceFilterUrl = (price: string) => {
  let returnPrice = ''
  const cleanPrice = price
    .replace('product_effectiveprice_cents=', '')
    .replace(/[[\]]/g, '')

  const divisorCents = 100
  const priceArr = cleanPrice.split(' TO ')
  const initPrice = priceArr[0]
  const endPrice = priceArr[1]

  if (initPrice === '0') {
    returnPrice = `preco=ate-${parseInt(endPrice, 10) / divisorCents}`
  } else if (endPrice === '*') {
    returnPrice = `preco=acima-de-${parseInt(initPrice, 10) / divisorCents}`
  } else {
    returnPrice = `preco=de-${parseInt(initPrice, 10) / divisorCents}-ate-${
      parseInt(endPrice, 10) / divisorCents
    }`
  }

  return returnPrice
}

/**
 * Função que pega o formato de filtro da rich e devolve como url
 */
export const parseFilterRich = (filter: string, or = false) => {
  if (or) {
    const value = filter.split('}')[1]

    const arrVal = value.split(':')
    const key = arrVal[0].trim()
    const orVals = arrVal[1].replace(/[)("]/g, '').split(' OR ')

    const returnVal = orVals.map((val) => {
      return `${key}=${val}`
    })

    return returnVal.join('&')
  }

  const returnFilter = filter
    .split('}')[1]
    .replace(/"/g, '')
    .replace(/\\/g, '')
    .replace(/:/g, '=')

  // caso seja preco, reescreve para uma url mais amigavel
  if (filter.includes('product_effectiveprice_cents')) {
    return decodePriceFilterUrl(returnFilter)
  }

  return returnFilter
}

/**
 * Função que formata o preço que aparece nos filtros selecionados da busca
 */
export const parseFilterRichPrice = (filter: string) => {
  // formato que pode vir no parametro > {!tag=product_effectiveprice_cents q.op=OR}product_effectiveprice_cents:([25000 TO *] OR [5000 TO 10000])
  // como deve sair nesse caso > (Cremosa OR Compacta)
  const arrFilt = filter.split(':')
  const valueFilt = arrFilt[1].split(' OR ')

  const toReturn = valueFilt.map((item) => {
    const itemClean = item.replace(/[([\])]/g, '')

    return decodePriceFilterUrl(itemClean).replace('preco=', '')
  })

  return `preco=${toReturn.join(' OR ')}`
}

/**
 * Codifica o valor do preço da url para o formato a ser enviado para rich
 */
export const encodePriceFilter = (price: string) => {
  // limpando os valores que foram colocados para deixar a url amigavel
  const cleanVal = price
    .toString()
    .replace('acima-de-', '')
    .replace('-ate-', ':')
    .replace('de-', '')
    .replace('ate-', '0:')

  const arrVal = cleanVal.split(':')

  if (arrVal.length === 1) {
    const newVal = parseInt(arrVal[0], 10) * 100

    return `[${newVal} TO *]`
  }

  const fistVal = parseInt(arrVal[0], 10) * 100
  const secoundVal = parseInt(arrVal[1], 10) * 100

  return `[${fistVal} TO ${secoundVal}]`
}

/**
 * Função que pega o filtro como url e transforma para o formato da rich
 */
// prettier-ignore
export const formatFilterRich = (filter: string): string[] => { // NOSONAR
  // formato que a rich precisa => {!tag=Textura q.op=OR}Textura:("Bastão" OR "Cremosa")
  // formato que a rich precisa => {!tag=product_brand}product_brand:"Essence"
  const filterArrFormated = []
  const filterParsed = parse(filter)
  const {
    q,
    utm_source, // eslint-disable-line
    utm_campaing, // eslint-disable-line
    utm_medium, // eslint-disable-line
    page,
    ordenacao,
    ...params
  } = filterParsed

  for (const tag in params) {
    if (Object.prototype.hasOwnProperty.call(params, tag)) {
      const value = params[tag]

      if (Array.isArray(value)) {
        const valuesFilter: any[] = []

        value.forEach((val) => {
          let valSend = val

          if (tag === 'preco') {
            valSend = encodePriceFilter(val.toString())
            valuesFilter.push(valSend)
          } else {
            valuesFilter.push(`"${valSend}"`)
          }
        })

        const valuesOr = valuesFilter.join(' OR ')

        let tagSend = tag

        if (tag === 'preco') {
          tagSend = 'product_effectiveprice_cents'
        }

        const formated = `{!tag=${tagSend} q.op=OR}${tagSend}:(${valuesOr})`

        filterArrFormated.push(formated)
      } else {
        let tagSend = tag
        let valSend = value

        let formated = `{!tag=${tagSend}}${tagSend}:"${valSend}"`

        if (tag === 'preco') {
          tagSend = 'product_effectiveprice_cents'
          valSend = value ? encodePriceFilter(value.toString()) : ''
          formated = `{!tag=${tagSend}}${tagSend}:${valSend}`
        }

        filterArrFormated.push(formated)
      }
    }
  }

  return filterArrFormated
}

/**
 * Função que renomeia os titulos dos filtros na sidebar
 */
export const formatTitlesRichSearch = (title: string) => {
  let formatedTitle = ''

  switch (title) {
    case 'product_brand':
      formatedTitle = 'Marca'
      break

    case 'brand':
      formatedTitle = 'Marca'
      break

    case 'product_effectiveprice_cents':
      formatedTitle = 'Preço'
      break

    case 'preco':
      formatedTitle = 'Preço'
      break

    default:
      formatedTitle = title
      break
  }

  return formatedTitle
}

/**
 * Ajusta o nome dos valores no sidebar de filtros
 * formato de entrada > "5000:10000", "25000:*"
 */
export const decodePriceFilter = (price: string) => {
  let returnPrice = ''
  const divisorCents = 100
  const priceArr = price.split(':')
  const initPrice = priceArr[0]
  const endPrice = priceArr[1]

  if (initPrice === '0') {
    returnPrice = `Até R$ ${parseInt(endPrice, 10) / divisorCents},00`
  } else if (endPrice === '*') {
    returnPrice = `Acima de R$ ${parseInt(initPrice, 10) / divisorCents},00`
  } else {
    returnPrice = `De R$ ${parseInt(initPrice, 10) / divisorCents},00 até R$ ${
      parseInt(endPrice, 10) / divisorCents
    },00`
  }

  return returnPrice
}

/**
 * Ajusta o texto que aparecerá nas tags de preço selecionado
 */
export const ajustPricesTagsView = (price: string) => {
  // formatos possíveis: de-100-ate-250 / ate-50 / acima-de-250

  if (price.indexOf('ate') === 0) {
    const finalVal1 = price.replace('ate-', '')

    return `Até R$ ${finalVal1},00`
  }

  if (price.indexOf('acima-de') === 0) {
    const finalVal2 = price.replace('acima-de-', '')

    return `Acima de R$ ${finalVal2},00`
  }

  const arrVal = price.split('-ate-')
  const initVal = arrVal[0].replace('de-', '')
  const finalVal3 = arrVal[1]

  return `De R$ ${initVal},00 até R$ ${finalVal3},00`
}
