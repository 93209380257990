import type { Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { useEffect, createContext, useState } from 'react'

import { searchOrderEncode } from 'src/utils'
import type { UserProps } from 'src/typings/Vtex'

import { User } from '../utils/RichRelevance'
import type { UserRichProps } from '../utils/RichRelevance/RichRelevance'
import { useInitFavPersion } from 'src/utils/Favoritos'

const isBrowser = () => typeof window !== 'undefined'

export interface ValuesPropsContext {
  menuOpen: boolean
  toggleMenu: () => void
  submenuMobileOpened: string
  subMenuMobileToggle: (menu: string) => void
  accessibleMenuFocus: string
  accessibleMenuFocusChange: (menu: string) => void
  location: Location | undefined
  richUserInfo: UserRichProps // seta a informação usuário para a rich
  rcsRich: string | null | undefined // guarda a informação de rcs que vem da rich
  setRcsRich: (rcs: string | null | undefined) => void // seta a informação de rcs que vem da rich
  urlParamsFilter: string[] // salva os filtros para a busca da rich
  setUrlParamsFilter: (param: string[]) => void
  renderActiveSearchFilters: RenderActiveSearchFiltersProps[]
  setRenderActiveSearchFilters: (
    param: RenderActiveSearchFiltersProps[]
  ) => void
  searchOrder: OptionsSearchOrderProps
  setSearchOrder: (param: OptionsSearchOrderProps) => void
  searchFilterOpen: boolean
  toggleSearchFilterOpen: (action?: boolean) => void
  vtexUserInfo: UserProps | null | undefined
  setVtexUserInfo: (param: UserProps | null | undefined) => void
  textoSeoCategory: string
  setTextoSeoCategory: (param: string) => void
  vitrinePatrocinadaCategory: boolean
  setVitrinePatrocinadaCategory: (param: boolean) => void
  categoryPage: categoryPageProps | null
  setCategoryPage: (param: categoryPageProps) => void
  skuSelecionado: any
  setSkuSelecionado: (param: any) => void
  modalFavOpen: boolean
  setModalFavOpen: Dispatch<SetStateAction<boolean>>
  contentModalFav: JSX.Element | null
  setContentModalFav: Dispatch<SetStateAction<JSX.Element | null>>
  modalLoaderOpen: boolean
  setModalLoaderOpen: Dispatch<SetStateAction<boolean>>
  favoritesCount: number
  setFavoritesCount: () => void
}

export interface RenderActiveSearchFiltersProps {
  title: string
  value: string
}

export interface categoryPageProps {
  key: string
  value: string
}

export interface OptionsSearchOrderProps {
  label: string
  value: string
}

export const StoreContext = createContext<ValuesPropsContext>(
  {} as ValuesPropsContext
)

export function StoreProvider({ children }: PropsWithChildren) {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [submenuMobileOpened, setSubMenuMobileOpened] = useState<string>('')
  const [accessibleMenuFocus, setAccessibleMenuFocus] = useState<string>('')
  const [rcsRich, setRcsRich] = useState<string | null | undefined>('')
  const [renderActiveSearchFilters, setRenderActiveSearchFilters] = useState<
    RenderActiveSearchFiltersProps[]
  >([])

  const [modalFavOpen, setModalFavOpen] = useState<boolean>(false)
  const [contentModalFav, setContentModalFav] = useState<JSX.Element | null>(
    null
  )

  const [modalLoaderOpen, setModalLoaderOpen] = useState<boolean>(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const subMenuMobileToggle = (menu: string) => {
    if (submenuMobileOpened === menu) {
      setSubMenuMobileOpened('')
    } else {
      setSubMenuMobileOpened(menu)
    }
  }

  const accessibleMenuFocusChange = (menu: string) => {
    if (submenuMobileOpened === menu) {
      setAccessibleMenuFocus('')
    } else {
      setAccessibleMenuFocus(menu)
    }
  }

  const [vtexUserInfo, setVtexUserInfo] = useState<
    UserProps | null | undefined
  >(null)

  const richUserInfo = User(vtexUserInfo?.email)

  const [urlParamsFilter, setUrlParamsFilter] = useState<string[]>([])

  const searchParams = isBrowser()
    ? new URLSearchParams(window.location.search)
    : []

  const location = isBrowser() ? window.location : undefined
  const orderParam = new URLSearchParams(searchParams).get('ordenacao')

  const initialOrder = searchOrderEncode(orderParam ?? '')

  const [searchOrder, setSearchOrder] =
    useState<OptionsSearchOrderProps>(initialOrder)

  const [searchFilterOpen, setSearchFilterOpen] = useState<boolean>(false)

  const toggleSearchFilterOpen = (action = false) => {
    if (action) {
      setSearchFilterOpen(action)
    } else {
      setSearchFilterOpen(!searchFilterOpen)
    }
  }

  const [textoSeoCategory, setTextoSeoCategory] = useState<string>('')
  const [vitrinePatrocinadaCategory, setVitrinePatrocinadaCategory] =
    useState<boolean>(false)

  const [categoryPage, setCategoryPage] = useState<categoryPageProps | null>(
    null
  )

  const [skuSelecionado, setSkuSelecionado] = useState<any>()
  const [favoritesCount, setFavoritesCountState] = useState(0)
  const favPersion = useInitFavPersion()

  const setFavoritesCount = () => {
    if (typeof window !== 'undefined') {
      const _countFav = window.localStorage.getItem('_countFav') ?? '0'

      setFavoritesCountState(parseInt(_countFav, 10))
    }
  }

  useEffect(() => {
    favPersion.then(() => {
      setFavoritesCount()
    })
  }, [favPersion, favoritesCount])

  const valuesProps: ValuesPropsContext = {
    menuOpen,
    toggleMenu,
    submenuMobileOpened,
    subMenuMobileToggle,
    accessibleMenuFocus,
    accessibleMenuFocusChange,
    location,
    richUserInfo,
    rcsRich,
    setRcsRich,
    urlParamsFilter,
    setUrlParamsFilter,
    renderActiveSearchFilters,
    setRenderActiveSearchFilters,
    searchOrder,
    setSearchOrder,
    searchFilterOpen,
    toggleSearchFilterOpen,
    vtexUserInfo,
    setVtexUserInfo,
    textoSeoCategory,
    setTextoSeoCategory,
    vitrinePatrocinadaCategory,
    setVitrinePatrocinadaCategory,
    categoryPage,
    setCategoryPage,
    skuSelecionado,
    setSkuSelecionado,
    modalFavOpen,
    setModalFavOpen,
    contentModalFav,
    setContentModalFav,
    modalLoaderOpen,
    setModalLoaderOpen,
    favoritesCount,
    setFavoritesCount,
  }

  return (
    <StoreContext.Provider value={valuesProps}>
      {children}
    </StoreContext.Provider>
  )
}
