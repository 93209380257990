import { useState } from 'react'
import { UserIcon } from '@heroicons/react/24/outline'

import SimpleButton from 'src/components/ui/Button'
import { useSession } from 'src/sdk/session'

import { SubMenuAccount } from './SubMenuAccount'
import styles from './account-menu.module.scss'
import { ChevronDownIcon } from 'src/components/ui/ImageGallery/Icons'

export const AccountMenu = () => {
  const [submenuOpen, setSubmenuOpen] = useState<boolean>(false)
  const { person } = useSession()

  const loggedIn = !!person?.id

  const handlerOpenMenu = () => {
    setSubmenuOpen(true)
  }

  const handlerCloseMenu = () => {
    setSubmenuOpen(false)
  }

  const msgWelcome = () => {
    if (loggedIn) {
      return (
        <>
          <span className="block flex-1 w-full leading-5">
            Oi, {person?.givenName}!{' '}
          </span>
          <span className="text-xxs leading-3">Que bom que voltou! ;)</span>
        </>
      )
    }

    return (
      <div className={styles.msgMenu}>
        <div className={styles.divMessage}>
          <span className="block flex-1 w-full leading-5 text-lg">Oie!</span>
          <span className="leading-3 text-xs">Vem fazer seu login ;)</span>
        </div>
        <div className={styles.icon}>
          <ChevronDownIcon color="#999" />
        </div>
      </div>
    )
  }

  return (
    <div className={`${styles.AccountMenu} group z-45 relative`}>
      <SimpleButton
        onFocus={handlerOpenMenu}
        onMouseOver={handlerOpenMenu}
        data-testid="buttonMenuAccount"
        aria-label="Botao Account"
        tabIndex={0}
        className="z-45 bg-white relative rounded-t xl:p-1"
      >
        <div className="user_content flex items-center xl:mr-3">
          <UserIcon className="w-[32px] h-[32px] icon-stroke-1 xl:mr-0.5" />
          <span className="msg_bemvindo hidden xl:flex flex-col text-left">
            {msgWelcome()}
          </span>
        </div>

        <div
          data-testid="subMenuAccount"
          id="subMenuAccount"
          className={`submenu_account
                      absolute right-0 w-max bg-white p-1 -ml-3 z-40 transition-all rounded-b rounded-l
                      drop-shadow-negative-1 xl:mt-1
                      ${
                        submenuOpen
                          ? 'visible opacity-100'
                          : 'invisible opacity-0'
                      }
                    `}
        >
          <SubMenuAccount handlerLast={handlerCloseMenu} logado={loggedIn} />
        </div>
      </SimpleButton>

      <div
        data-testid="overlayAccount"
        onMouseOver={handlerCloseMenu}
        onFocus={handlerCloseMenu}
        className={`overlay_account
                    fixed inset-0 z-40 bg-black min-h-screen transition-all opacity-30
                    ${submenuOpen ? 'visible' : 'invisible opacity-0'}
                  `}
      />
    </div>
  )
}
