import { utils } from 'store.config'

export const getTokenOptin = async () => {
  const dataToken =
    'eyJlbWFpbCI6ImFwcGVwb2NhQGVwb2NhY29zbWV0aWNvcy5jb20uYnIiLCJwYXNzd29yZCI6IjBoNzRSNiMifQ=='

  const data = atob(dataToken)

  const responseFetch = await fetch(`${utils.optinUrl}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.vtex.ds.v10+json',
    },
    body: data,
  })

  const responseData = responseFetch.json()

  return responseData
}

export const setEmailOptin = async (email: string, auth: string) => {
  const responseFetch = await fetch(`${utils.optinUrl}/cr-opt`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.vtex.ds.v10+json',
      Authorization: `Bearer ${auth}`,
    },
    body: JSON.stringify({
      email,
      opt: true,
      channel: 'email',
    }),
  })

  const responseData = await responseFetch.text()

  return [responseFetch.status, responseData]
}
