type IconProps = {
  color: string
  tamanho?: string
}

export function BackwardArrowIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.30651 1.81475C7.59941 1.52186 7.59941 1.04699 7.30651 0.754094C7.01362 0.461201 6.53875 0.461201 6.24585 0.754094L0.480229 6.51972C0.408322 6.59163 0.354068 6.6745 0.317468 6.76296C0.280798 6.85139 0.260559 6.94836 0.260559 7.05005C0.260559 7.15174 0.280798 7.24871 0.317468 7.33714C0.354068 7.4256 0.408322 7.50847 0.480229 7.58038L6.24585 13.346C6.53875 13.6389 7.01362 13.6389 7.30651 13.346C7.59941 13.0531 7.59941 12.5782 7.30651 12.2853L2.82122 7.80005H15.1043C15.5185 7.80005 15.8543 7.46426 15.8543 7.05005C15.8543 6.63584 15.5185 6.30005 15.1043 6.30005H2.82122L7.30651 1.81475Z"
        fill={props.color}
      />
    </svg>
  )
}

export function ForwardArrowIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.84198 0.754094C9.54908 0.4612 9.07421 0.461201 8.78132 0.754094C8.48842 1.04699 8.48842 1.52186 8.78132 1.81475L13.2666 6.30005H0.983521C0.569307 6.30005 0.233521 6.63583 0.233521 7.05005C0.233521 7.46426 0.569307 7.80005 0.983521 7.80005H13.2666L8.78132 12.2853C8.48842 12.5782 8.48842 13.0531 8.78132 13.346C9.07421 13.6389 9.54908 13.6389 9.84198 13.346L15.6076 7.58038C15.7483 7.43973 15.8273 7.24896 15.8273 7.05005C15.8273 6.85114 15.7483 6.66037 15.6076 6.51972L9.84198 0.754094Z"
        fill={props.color}
      />
    </svg>
  )
}

export function ChevronDownIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={props.tamanho ? props.tamanho : 'h-3 w-3'}
    >
      <path
        fillRule="evenodd"
        d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
        clipRule="evenodd"
        fill={props.color}
      />
    </svg>
  )
}

export function ChevronUpIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={props.tamanho ? props.tamanho : 'h-3 w-3'}
    >
      <path
        fillRule="evenodd"
        d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z"
        clipRule="evenodd"
        fill={props.color}
      />
    </svg>
  )
}

export function MinIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-3 w-3 text-white"
      fill="none"
      viewBox="0 0 20 20"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18 12H6"
        fill={props.color}
      />
    </svg>
  )
}

export function XIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${props.tamanho ? props.tamanho : 'h-3 w-3'}`}
      viewBox="0 0 20 20"
      fill={props.color}
    >
      <path
        fillRule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  )
}
