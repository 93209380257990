import { storeLinks } from 'store.config'
import styles from './acessibilidade-button.module.scss'

export const AcessibilityButtonHeader = () => {
  return (
    <div className={styles.wrappeAcessibilidade}>
      <a
        href={storeLinks.acessibilidade}
        className={styles.AcessibilityButtonHeader}
      >
        <span>Atendimento em Libras</span>
        <img src="/acessibilidade-40-40.svg" alt="Icone Acessibilidade" />
      </a>
    </div>
  )
}
