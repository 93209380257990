import { HeartIcon } from '@heroicons/react/24/outline'
import { useContext } from 'react'
import { StoreContext } from 'src/providers/StoreProvider'

import styles from './heart.module.scss'

interface Props {
  className?: string
  tabIndex?: number
  showTooltip?: boolean
  tooltipContent?: string
  onClick?: (e: React.MouseEvent) => void
  href?: string
}

export const HeartButtonHeader = (props: Props) => {
  const { favoritesCount } = useContext(StoreContext)

  const {
    tabIndex,
    showTooltip,
    tooltipContent,
    onClick,
    href,
    ...otherProps
  } = props

  const handlerClick = (event: React.MouseEvent) => {
    if (onClick && !href) {
      onClick(event)
    }

    if (href) {
      window.location.href = '/_secure/favoritos'
    }
  }

  return (
    <button
      data-testid="btnFavoritosHeader"
      type="button"
      tabIndex={tabIndex}
      aria-label="Favoritos"
      className={`${styles.heartHeader} relative flex items-center sm:px-1 group`}
      onClick={handlerClick}
    >
      <HeartIcon {...otherProps} />
      <span
        className="badge bg-pink rounded-full text-white absolute
                  sm:-top-[5px] -top-[1px] sm:-right-[1px] -right-[5px] text-xxs font-semibold flex items-center justify-center
                  w-[15px] h-[15px]
                  xl:w-[20px] xl:h-[20px]"
      >
        {favoritesCount}
      </span>
      {showTooltip && tooltipContent ? (
        <div
          data-testid="favoritosTooltip"
          className={`${styles.tooltip} absolute left-0 flex justify-center w-full bottom-full mb-[3px] invisible opacity-0
                        group-hover:opacity-100 group-hover:visible transition-all duration-300 z-50
                        group-focus-within:opacity-100 group-focus-within:visible`}
        >
          <span
            className="text-xxs py-[3px] px-[5px] -top-2 bg-millennial rounded-sm whitespace-nowrap
                          after:absolute after:border-x-4 after:h-1 after:border-t-4 after:-bottom-1 after:left-1/2
                          after:-ml-[4px] after:border-l-transparent after:border-transparent after:border-t-millennial after:bg-opacity-0"
          >
            {tooltipContent}
          </span>
        </div>
      ) : (
        ''
      )}
    </button>
  )
}
