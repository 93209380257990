import slugify from 'slugify'
import storeConfig from 'store.config'
import type { MouseEvent } from 'react'
import { useCart } from 'src/sdk/cart'

export interface UserMenuProps {
  handlerBlur: (ast: boolean) => boolean
}

const secureSubdomain = storeConfig.secureSubdomain

export const UserMenu = ({ handlerBlur }: UserMenuProps) => {
  const rand = Math.floor(Math.random() * 1000)
  const defaultClasses = `menuitem_account hover:text-pink focus-within:text-pink block transition-all text-left px-0.5 `

  const cart = useCart()
  const urlLogout = `${secureSubdomain}/no-cache/user/logout`

  const handlerLogout = (
    e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
  ) => {
    e.preventDefault()
    try {
      const orderFormId = cart.id

      if (orderFormId) {
        const urlCheckout = `/vtex/checkout/changeToAnonymousUser/${orderFormId}`

        console.log(urlCheckout)
        fetch(urlCheckout).then(() => {
          window.location.href = urlLogout
        })
      } else {
        window.location.href = urlLogout
      }
    } catch (error) {
      console.info(error)
    }
  }

  const itemsAccountLogado = [
    {
      texto: 'Minha Conta',
      link: `${secureSubdomain}/_secure/account#/profile`,
      logout: false,
    },
    {
      texto: 'Meus Pedidos',
      link: `${secureSubdomain}/_secure/account/#/orders`,
      logout: false,
    },
    {
      texto: 'Rastrear meus Pedidos',
      link: '/centraldeatendimento/rastrear-pedido',
      logout: false,
    },
    {
      texto: 'Atendimento',
      link: 'https://atende.epocacosmeticos.com.br/hc/pt-br',
      logout: false,
    },
    {
      texto: 'Não é você? Sair.',
      link: `${secureSubdomain}/no-cache/user/logout`,
      logout: true,
    },
  ]

  return (
    <>
      {itemsAccountLogado.map((itemMenu, index) => {
        const keyItem = slugify(itemMenu.texto.toLocaleLowerCase())

        return (
          <li className="py-0.5" key={`${keyItem}_i_${index}`}>
            <a
              role="menuitem"
              tabIndex={0}
              href={`${itemMenu.link}?v=${rand}`}
              className={`${keyItem} ${defaultClasses} ${rand}_${index}`}
              onBlur={() =>
                handlerBlur(index === itemsAccountLogado.length - 1)
              }
              onClick={(e) => (itemMenu.logout ? handlerLogout(e) : false)}
            >
              {itemMenu.texto}
            </a>
          </li>
        )
      })}
    </>
  )
}
